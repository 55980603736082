import {FC, useEffect, useState} from 'react'
import {EnableSidebar} from '../../../_metronic/layout/core'
import {Form, Formik} from 'formik'
import {KTIcon} from '../../../_metronic/helpers'
import {useFlash} from '../../components/support/Alerts'
import {useNavigate} from 'react-router-dom'
import {StartLoading, StopLoading} from '../../components/support/Script'
import {useAuth} from '../auth'
import {FormatAPI, GeneralInputType, PropiedadParametrica} from '../../components/inputs/Interfaces'
import {ConstructForm, GeneralInput} from '../../components/inputs/GeneralInput'
import {getDataRequest} from '../../components/support/TableRequests'
interface obj_detail {
  codigo_proceso: string
  key: string
}
const NuevaSolicitudCarta: FC = () => {
  const navigate = useNavigate()
  const [currentSchema, setCurrentSchema] = useState({})
  const {setAlert} = useFlash()
  const [initValues, setInitialValues] = useState<PropiedadParametrica>({})
  const {currentUser, setCurrentUser} = useAuth()
  const [form, setForm] = useState<GeneralInputType[]>()
  const [valuesAPI, setValuesAPI] = useState<FormatAPI[]>([])
  useEffect(() => {
    if (currentUser !== undefined && currentUser.dataRequest !== undefined && form === undefined) {
      const url = process.env.REACT_APP_URL_API_FORMULARIO?.toString() ?? ''
      if (url !== '') {
        StartLoading()
        var details: obj_detail = {
          codigo_proceso: 'RHCARTA',
          key: process.env.REACT_APP_TOKEN_FORMULARIO?.toString() ?? '123',
        }
        var formBody = []
        for (var property in details) {
          var encodedKey = encodeURIComponent(property)
          var encodedValue = encodeURIComponent(details[property as keyof obj_detail])
          formBody.push(encodedKey + '=' + encodedValue)
        }
        const formBodyFetch = formBody.join('&')
        fetch(`${url}/ticket/formatoSolicitud`, {
          method: 'POST',
          mode: 'cors',
          body: formBodyFetch,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        })
          .then((response) => response.json())
          .then((response) => {
            ConstructForm(
              response,
              StopLoading,
              setForm,
              setCurrentSchema,
              setInitialValues,
              setValuesAPI,
              currentUser
            )
          })
          .catch((e) => {
            if (e.message.search('Failed to fetch') >= 0) {
              setAlert({type: 'danger', message: 'Error de consumo hacia el web service'})
            } else {
              setAlert({type: 'danger', message: e.message})
            }
            StopLoading()
          })
      } else {
        setAlert({type: 'danger', message: 'Error en traer formulario'})
      }
    }
  }, [setAlert, currentUser?.dataRequest, form, currentUser])
  const submit = (values: PropiedadParametrica) => {
    const url = process.env.REACT_APP_URL_API_FORMULARIO?.toString() ?? ''
    if (url !== '') {
      StartLoading()
      var formBody = []
      const details = {
        key: process.env.REACT_APP_TOKEN_FORMULARIO?.toString() ?? '123',
        codigo_proceso: 'RHCARTA',
        correo: currentUser?.email,
        departamento:
          currentUser?.data !== undefined && 'departamento' in currentUser?.data
            ? currentUser?.data.departamento
            : '',
        grupo:
          currentUser?.data !== undefined && 'grupo' in currentUser?.data
            ? currentUser?.data.grupo
            : '',
        json_valores: JSON.stringify({data: valuesAPI}),
      }
      for (var property in details) {
        var encodedKey = encodeURIComponent(property)
        var encodedValue = encodeURIComponent(details[property as keyof obj_detail])
        formBody.push(encodedKey + '=' + encodedValue)
      }
      const formBodyFetch = formBody.join('&')
      fetch(`${url}/ticket/solicita`, {
        method: 'POST',
        mode: 'cors',
        body: formBodyFetch,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          StopLoading()
          if (
            (data.valido === '1' || data.valido === true || data.valido === 1) &&
            data.mensaje === 'SOLICITUD REALIZADA CON EXITO'
          ) {
            getDataRequest(currentUser?.email, setCurrentUser)
            setAlert({type: 'success', message: 'Registro almacenado correctamente'})
            navigate('/solicitud_carta', {replace: true})
          } else {
            setAlert({type: 'danger', message: data.mensaje})
          }
        })
        .catch((e) => {
          setAlert({type: 'danger', message: e.message})
          StopLoading()
        })
    } else {
      setAlert({type: 'danger', message: 'URL_GATE WAY no definido'})
    }
  }
  return (
    <EnableSidebar>
      <h2>Solicitud de Carta</h2>
      <Formik
        validationSchema={currentSchema}
        enableReinitialize={true}
        initialValues={initValues}
        onSubmit={submit}
      >
        {({errors, touched, values, setTouched, resetForm}) => {
          return (
            <Form className='mx-auto pt-15 pb-10'>
              <div className='row'>
                {form !== undefined &&
                  form.map((object: GeneralInputType, index: number) => {
                    return (
                      <GeneralInput
                        key={index}
                        obj={object}
                        errors={errors}
                        touched={touched}
                        values={values}
                      />
                    )
                  })}
              </div>
              <div className='d-flex flex-stack pt-15'>
                <button
                  type='submit'
                  className='btn  btn-primary me-3'
                  style={{backgroundColor: '#182C59'}}
                >
                  <span className='indicator-label'>
                    <KTIcon iconName='check' />
                    Solicitar
                  </span>
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </EnableSidebar>
  )
}

export default NuevaSolicitudCarta
