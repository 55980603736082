import React, {useEffect} from 'react'
import InputComplete from './InputComplete'
import Select2CompleteBoolean from './Select2CompleteBoolean'
import InputDatePicker from './InputDatePicker'
type PasosProps = {
  errors: {}
  touched: {}
  values: any
  setTouched: any
  step: number
}
const Paso8 = ({errors, touched, values, setTouched, step}: PasosProps) => {
  useEffect(() => {
    setTouched({})
  }, [step, setTouched])
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Condiciones de Trabajo</h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6 col-md-6'>
            <Select2CompleteBoolean
              touched={touched}
              errors={errors}
              label={'¿Esta dispuesto a viajar?'}
              name={`dispuestoAViajar`}
              placeholder={'¿Esta dispuesto a viajar?'}
              options={[
                {label: 'Si', value: true},
                {label: 'No', value: false},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2CompleteBoolean
              touched={touched}
              errors={errors}
              label={'¿Tiene pasaporte vigente?'}
              name={`pasaporteVigente`}
              placeholder={'¿Tiene pasaporte vigente?'}
              options={[
                {label: 'Si', value: true},
                {label: 'No', value: false},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Número de pasaporte'}
              name={`numPasaporte`}
              placeholder={'Número de pasaporte'}
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2CompleteBoolean
              touched={touched}
              errors={errors}
              label={'¿Tiene limitaciones de horario?'}
              name={`limitacionesHorario`}
              placeholder={'¿Tiene limitaciones de horario?'}
              options={[
                {label: 'Si', value: true},
                {label: 'No', value: false},
              ]}
              required={true}
            />
          </div>
          {values.limitacionesHorario ? (
            <div className='col-lg-6 col-md-6'>
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Especifique sus limitaciones de horario'}
                name={`detalleLimitacionesHorario`}
                placeholder={'Especifique sus limitaciones de horario'}
                required={true}
              />
            </div>
          ) : null}
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'¿Tiene discapacidades fisicas?'}
              name={`discapacidadFisica`}
              placeholder={'¿Tiene discapacidades fisicas?'}
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Ingrese su expectativa salarial'}
              name={`expectativaSalaria`}
              placeholder={'Ingrese su expectativa salarial'}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputDatePicker
              touched={touched}
              errors={errors}
              label={'Fecha en la que iniciaria con nosotros'}
              name={`fechaInicioTrabajo`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export {Paso8}
