import React, {FC} from 'react'
import {Text, View} from '@react-pdf/renderer'
import {
  Idioma,
  JobRequest,
  TituloUniversitario,
  formatearFecha,
  formatoSiNo,
} from '../components/NuevoEmpleoHelper'
interface NuevoEmpleoHelperPDF {
  styles: any
  data?: JobRequest
}
const Educacion: FC<NuevoEmpleoHelperPDF> = ({styles, data}) => {
  return (
    <>
      <View style={{textAlign: 'left', marginTop: 10, marginLeft: 50}}>
        <Text>D. EDUCACIÓN</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '100%'}}>
            <Text style={styles.tableCell}>ESTUDIOS PREVIOS A LA UNIVERSIDAD</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Establecimiento:</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.colegioDiversificado}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Titulo / diploma obtenido:</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.tituloObtenido}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Mes y año de graduación</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>
              {data && data.fechaGraduacion !== undefined
                ? formatearFecha(data?.fechaGraduacion)
                : ''}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '100%'}}>
            <Text style={styles.tableCell}>UNIVERSITARIOS</Text>
          </View>
        </View>
        {data?.titulosUniversitarios.map(function (titulo: TituloUniversitario, index: number) {
          return (
            <View key={index}>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Universidad:</Text>
                </View>
                <View style={{...styles.tableCol, width: '20%'}}>
                  <Text style={styles.tableCell}>{titulo.universidad}</Text>
                </View>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Carrera:</Text>
                </View>
                <View style={{...styles.tableCol, width: '20%'}}>
                  <Text style={styles.tableCell}>{titulo.carrera}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>No. de cursos aprobados:</Text>
                </View>
                <View style={{...styles.tableCol, width: '70%'}}>
                  <Text style={styles.tableCell}>{titulo.noCursosAprobados}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Fecha de Graduación:</Text>
                </View>
                <View style={{...styles.tableCol, width: '70%'}}>
                  <Text style={styles.tableCell}>{formatearFecha(titulo.fechaGraduacion)}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Premios y menciones:</Text>
                </View>
                <View style={{...styles.tableCol, width: '70%'}}>
                  <Text style={styles.tableCell}>{titulo.premiosOMenciones}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Estudia actualmente:</Text>
                </View>
                <View style={{...styles.tableCol, width: '70%'}}>
                  <Text style={styles.tableCell}>{formatoSiNo(titulo.estudiaActualmente)}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Horario:</Text>
                </View>
                <View style={{...styles.tableCol, width: '70%'}}>
                  <Text style={styles.tableCell}>{titulo.horario}</Text>
                </View>
              </View>
            </View>
          )
        })}
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '100%'}}>
            <Text style={styles.tableCell}>IDIOMAS</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={{...styles.tableCell, textAlign: 'center'}}>
              Idioma adicional al español
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={{...styles.tableCell, textAlign: 'center'}}>Hablado</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={{...styles.tableCell, textAlign: 'center'}}>Escrito</Text>
          </View>
        </View>
        {data?.idiomas.map(function (value: Idioma, index: number) {
          return (
            <View style={styles.tableRow} key={index}>
              <View style={{...styles.tableCol, width: '40%'}}>
                <Text style={{...styles.tableCell, textAlign: 'center'}}>{value.idioma}</Text>
              </View>
              <View style={{...styles.tableCol, width: '30%'}}>
                <Text style={{...styles.tableCell, textAlign: 'center'}}>{value.nivelHablado}</Text>
              </View>
              <View style={{...styles.tableCol, width: '30%'}}>
                <Text style={{...styles.tableCell, textAlign: 'center'}}>{value.nivelEscrito}</Text>
              </View>
            </View>
          )
        })}
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Conocimientos en Computadoras</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>
              {data?.conocimientoComputadoras ? formatoSiNo(data?.conocimientoComputadoras) : ''}
            </Text>
          </View>
        </View>
      </View>
    </>
  )
}
export default Educacion
