import React, {useEffect} from 'react'
import InputComplete from './InputComplete'
import Select2Complete from './Select2Complete'
import {FieldArray} from 'formik'
import {KTIcon} from '../../../../_metronic/helpers'
import Select2CompleteBoolean from './Select2CompleteBoolean'
import InputDatePicker from './InputDatePicker'
type PasosProps = {
  errors: {}
  touched: {}
  values: any
  setTouched: any
  step: number
}
const Paso4 = ({errors, touched, values, setTouched, step}: PasosProps) => {
  useEffect(() => {
    setTouched({})
  }, [step, setTouched])
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Educación</h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-12 col-lg-12'>
            <h4>Estudios previos a la universidad</h4>
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Establecimiento'}
              name={'colegioDiversificado'}
              placeholder='Establecimiento'
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Título obtenido / Diploma obtenido'}
              name={'tituloObtenido'}
              placeholder='Título obtenido / Diploma obtenido'
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputDatePicker
              errors={errors}
              touched={touched}
              label={'Fecha de graduación'}
              name={'fechaGraduacion'}
              placeholder='Fecha de graduación'
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2CompleteBoolean
              touched={touched}
              errors={errors}
              label={'¿Posee conocimientos de manejo de computadora?'}
              name={`conocimientoComputadoras`}
              placeholder={'¿Posee conocimientos de manejo de computadora?'}
              options={[
                {label: 'Si', value: true},
                {label: 'No', value: false},
              ]}
              required={true}
            />
          </div>
          <div className='col-md-12 col-lg-12'>
            <hr />
          </div>
        </div>
        <FieldArray
          name='titulosUniversitarios'
          render={(arrayHelpers) => (
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                <h3>
                  Estudios universitarios
                  <button
                    type='button'
                    onClick={() => {
                      return arrayHelpers.push({
                        universidad: '',
                        carrera: '',
                        concluido: null,
                        noCursosAprobados: 0,
                        fechaGraduacion: new Date(),
                        premiosOMenciones: '',
                        estudiaActualmente: null,
                        horario: '',
                      })
                    }}
                    className='btn btn-sm btn-circle btn-icon btn-success btn-active-light-primary btn-custom position-relative'
                    style={{width: 25, height: 25, marginLeft: 20, marginBottom: 2}}
                  >
                    <KTIcon iconName='plus' />
                  </button>
                </h3>
              </div>

              {values.titulosUniversitarios.map((titulosUniversitarios: {}, index: number) => {
                return (
                  <>
                    <div className='col-md-12 col-lg-12'>
                      <h4>
                        Estudio No. {index + 1}
                        <button
                          type='button'
                          onClick={() => {
                            return arrayHelpers.remove(index)
                          }}
                          className='btn btn-sm btn-circle btn-icon btn-danger btn-active-light-primary btn-custom position-relative'
                          style={{width: 25, height: 25, marginLeft: 20, marginBottom: 2}}
                        >
                          <KTIcon iconName='minus' />
                        </button>
                      </h4>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                      <InputComplete
                        errors={errors}
                        touched={touched}
                        label={'Universidad'}
                        name={`titulosUniversitarios[${index}].universidad`}
                        placeholder='Universidad'
                      />
                    </div>
                    <div className='col-md-6 col-lg-6'>
                      <InputComplete
                        errors={errors}
                        touched={touched}
                        label={'Carrera'}
                        name={`titulosUniversitarios[${index}].carrera`}
                        placeholder='Carrera'
                      />
                    </div>
                    <div className='col-md-6 col-lg-6'>
                      <InputComplete
                        errors={errors}
                        touched={touched}
                        label={'No. Cursos Aprobados'}
                        name={`titulosUniversitarios[${index}].noCursosAprobados`}
                        placeholder='No. Cursos Aprobados'
                      />
                    </div>
                    <div className='col-md-6 col-lg-6'>
                      <InputComplete
                        errors={errors}
                        touched={touched}
                        label={'Premios o Menciones'}
                        name={`titulosUniversitarios[${index}].premiosOMenciones`}
                        placeholder={'Premios o Menciones'}
                        required={false}
                      />
                    </div>
                    <div className='col-md-6 col-lg-6'>
                      <Select2CompleteBoolean
                        touched={touched}
                        errors={errors}
                        label={'Carrera concluida'}
                        name={`titulosUniversitarios[${index}].concluido`}
                        placeholder='Tiene otros ingresos?'
                        options={[
                          {label: 'Si', value: true},
                          {label: 'No', value: false},
                        ]}
                        required={true}
                      />
                    </div>
                    <div className='col-md-6 col-lg-6'>
                      {values.titulosUniversitarios[index].concluido ? (
                        <InputDatePicker
                          errors={errors}
                          touched={touched}
                          label={'Fecha de Graduación'}
                          name={`titulosUniversitarios[${index}].fechaGraduacion`}
                          required={true}
                        />
                      ) : null}
                    </div>

                    <div className='col-md-6 col-lg-6'>
                      <Select2CompleteBoolean
                        touched={touched}
                        errors={errors}
                        label={'¿Estudia actualmente?'}
                        name={`titulosUniversitarios[${index}].estudiaActualmente`}
                        placeholder={'¿Estudia actualmente?'}
                        options={[
                          {label: 'Si', value: true},
                          {label: 'No', value: false},
                        ]}
                        required={true}
                      />
                    </div>

                    <div className='col-md-6 col-lg-6'>
                      {values.titulosUniversitarios[index].estudiaActualmente ? (
                        <Select2Complete
                          touched={touched}
                          errors={errors}
                          label={'Horario'}
                          name={`titulosUniversitarios[${index}].horario`}
                          placeholder={'Horario'}
                          options={[
                            {label: 'Diario', value: 'Diario'},
                            {label: 'Nocturno', value: 'Nocturno'},
                            {label: 'Sabatino', value: 'Sabatino'},
                            {label: 'Dominical', value: 'Dominical'},
                          ]}
                          required={true}
                        />
                      ) : null}
                    </div>
                    <div className='col-md-12 col-lg-12'>
                      <hr />
                    </div>
                  </>
                )
              })}
            </div>
          )}
        />
        <FieldArray
          name='idiomas'
          render={(arrayHelpers) => (
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                <h3>
                  Idiomas
                  <button
                    type='button'
                    onClick={() => {
                      return arrayHelpers.push({
                        idioma: '',
                        nivelHablado: '',
                        nivelEscrito: '',
                      })
                    }}
                    className='btn btn-sm btn-circle btn-icon btn-success btn-active-light-primary btn-custom position-relative'
                    style={{width: 25, height: 25, marginLeft: 20, marginBottom: 2}}
                  >
                    <KTIcon iconName='plus' />
                  </button>
                </h3>
              </div>
              {values.idiomas.map((idiomas: {}, index: number) => {
                return (
                  <>
                    <div className='col-md-12 col-lg-12'>
                      <h4>
                        Idioma No. {index + 1}
                        <button
                          type='button'
                          onClick={() => {
                            return arrayHelpers.remove(index)
                          }}
                          className='btn btn-sm btn-circle btn-icon btn-danger btn-active-light-primary btn-custom position-relative'
                          style={{width: 25, height: 25, marginLeft: 20, marginBottom: 2}}
                        >
                          <KTIcon iconName='minus' />
                        </button>
                      </h4>
                    </div>
                    <div className='col-md-12 col-lg-12'>
                      <InputComplete
                        errors={errors}
                        touched={touched}
                        label={'Idioma'}
                        name={`idiomas[${index}].idioma`}
                        placeholder='Idioma'
                      />
                    </div>
                    <div className='col-md-6 col-lg-6'>
                      <Select2Complete
                        touched={touched}
                        errors={errors}
                        label={'Nivel Hablado'}
                        name={`idiomas[${index}].nivelHablado`}
                        placeholder={'Nivel Hablado'}
                        options={[
                          {label: 'Basico', value: 'Basico'},
                          {label: 'Intermedio', value: 'Intermedio'},
                          {label: 'Avanzado', value: 'Avanzado'},
                        ]}
                        required={true}
                      />
                    </div>
                    <div className='col-md-6 col-lg-6'>
                      <Select2Complete
                        touched={touched}
                        errors={errors}
                        label={'Nivel Escrito'}
                        name={`idiomas[${index}].nivelEscrito`}
                        placeholder={'Nivel Escrito'}
                        options={[
                          {label: 'Basico', value: 'Basico'},
                          {label: 'Intermedio', value: 'Intermedio'},
                          {label: 'Avanzado', value: 'Avanzado'},
                        ]}
                        required={true}
                      />
                    </div>
                    <div className='col-md-12 col-lg-12'>
                      <hr />
                    </div>
                  </>
                )
              })}
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default Paso4
