import React, {FC} from 'react'
import {Text, View} from '@react-pdf/renderer'
import {JobRequest, formatoSiNo} from '../components/NuevoEmpleoHelper'
interface NuevoEmpleoHelperPDF {
  styles: any
  data?: JobRequest
}
const Salud: FC<NuevoEmpleoHelperPDF> = ({styles, data}) => {
  return (
    <>
      <View style={{textAlign: 'left', marginTop: 10, marginLeft: 50}}>
        <Text>G. SALUD EN GENERAL</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>
              ¿Ha padecido de alguna enfermedad en los últimos 3 años?
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{formatoSiNo(data?.enfermedad)}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Especifique</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.detalleEnfermedad}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>¿Ha padecido de COVID-19?</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{formatoSiNo(data?.covid)}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>¿Está vacunado contra COVID-19?</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{formatoSiNo(data?.vacunadoCovid)}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Nombre de la vacuna:</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>{data?.vacunaCovid}</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>Dosis:</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>{data?.dosisVacunaCovid}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Si es mujer: ¿Está actualmente embarazada?</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{formatoSiNo(data?.embarazada)}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>¿Es alérgico a algún medicamento?</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.alergia}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>¿Cuál es su tipo de sangre?</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.tipoSangre}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>¿Fuma, ingiere licor o algún tipo de droga?</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>{formatoSiNo(data?.fumaLicorDroga)}</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>Especifique:</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>{data?.detalleFumaLicorDroga}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>¿Tiene tatuajes en el cuerpo?</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{formatoSiNo(data?.tatuajes)}</Text>
          </View>
        </View>
      </View>
    </>
  )
}
export default Salud
