import React, {FC} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import Select from 'react-select'
import {isObject, useField, useFormikContext, getIn} from 'formik'
import {ErrorMessage} from 'formik'
export type OptionsType = {
  value: string
  label: string
}
type SelectCompleteType = {
  errors: {}
  touched: {}
  label: string
  name: string
  placeholder: string
  required?: boolean
  options: OptionsType[]
  multiple?: boolean
  fields?: any
}
const Select2Complete: FC<SelectCompleteType> = ({
  errors,
  label,
  name,
  placeholder,
  required = true,
  options,
  touched,
  multiple = false,
  fields,
}) => {
  const submit_form = getIn(touched, name)
  const submit_form_error = getIn(errors, name)
  const fields_validate = getIn(fields, name)
  const {setFieldValue} = useFormikContext() ?? {}
  const [field] = useField(name)

  let rows = []
  for (let i = 0; i < options.length; i++) {
    rows.push({value: options[i].value, label: options[i].label})
  }
  return (
    <>
      <label className={'form-label ' + (required ? 'required' : '')}>{label}</label>
      <div className='position-relative'>
        <Select
          name={name}
          value={options ? options.find((option) => option.value === field.value) : ''}
          className='form-control-solid'
          options={rows}
          placeholder='[Seleccione una opción]'
          isMulti={multiple}
          onChange={(val: any) => {
            if (isObject(val) && val['value'] !== undefined) {
              setFieldValue(field.name, val.value)
            } else {
              let object_new = []
              for (let i = 0; i < val.length; i++) {
                object_new.push(val[i].value)
              }
              setFieldValue(field.name, object_new)
            }
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: '#F9F9F9',
              borderColor: '#F9F9F9',
              color: 'red',
            }),
            option: (styles, {data, isDisabled, isFocused, isSelected}) => ({
              ...styles,
              backgroundColor: isFocused ? '#DCDEE1' : 'white',
              color: isFocused ? 'black' : 'gray',
              fontSize: '11pt',
            }),
            input: (styles) => ({
              ...styles,
              color: '#565674',
              fontSize: '11pt',
            }),
            placeholder: (styles) => ({
              ...styles,
            }),
            singleValue: (styles) => ({
              ...styles,
              color: 'black',
              fontSize: '11pt',
            }),
          }}
        />
        <div
          className={
            'position-absolute translate-middle-y top-50 end-0 ' + (multiple ? 'me-18' : 'me-12')
          }
        >
          {submit_form && fields_validate && !submit_form_error ? (
            <KTIcon iconName='check' iconType='solid' className='fs-2hx text-success' />
          ) : submit_form && fields_validate ? (
            <KTIcon iconName='cross' iconType='solid' className='fs-2hx text-danger' />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='text-danger mt-2'>
        <ErrorMessage name={name} />
      </div>
    </>
  )
}

export default Select2Complete
