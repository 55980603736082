import React, {useEffect} from 'react'
import InputComplete from './InputComplete'
import {FieldArray} from 'formik'
type PasosProps = {
  errors: {}
  touched: {}
  values: any
  setTouched: any
  step: number
}
const Paso9 = ({errors, touched, values, setTouched, step}: PasosProps) => {
  useEffect(() => {
    setTouched({})
  }, [step, setTouched])
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Referencias Personales</h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <FieldArray
            name='referenciasPersonales'
            render={(arrayHelpers) => (
              <>
                {values.referenciasPersonales.map((referenciasPersonales: [], index: number) => {
                  return (
                    <div key={index} className='row'>
                      <div className='col-md-12 col-lg-12'>
                        <h5>Referencia No. {index + 1}</h5>
                      </div>
                      <div className='col-md-4 col-lg-4'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Nombre completo'}
                          name={`referenciasPersonales[${index}].nombre`}
                          placeholder='Nombre completo'
                          required={true}
                        />
                      </div>
                      <div className='col-md-4 col-lg-4'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Télefono'}
                          name={`referenciasPersonales[${index}].telefono`}
                          placeholder='Télefono'
                          required={true}
                        />
                      </div>
                      <div className='col-md-4 col-lg-4'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Relación'}
                          name={`referenciasPersonales[${index}].relacion`}
                          placeholder='Relación'
                          required={true}
                        />
                      </div>
                    </div>
                  )
                })}
              </>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default Paso9
