import React, {FC} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Field, getIn, ErrorMessage} from 'formik'
type InputCompleteType = {
  errors: any
  touched: {}
  label: string
  name: string
  placeholder: string
  required?: boolean
}
const InputComplete: FC<InputCompleteType> = ({
  errors,
  touched,
  label,
  name,
  placeholder,
  required = true,
}) => {
  const submit_form = getIn(touched, name)
  const submit_form_error = getIn(errors, name)
  return (
    <>
      {label !== '' ? (
        <label className={'form-label ' + (required ? 'required' : '')}>{label}</label>
      ) : null}
      <div className='position-relative'>
        <Field
          type='text'
          className='form-control form-control-solid'
          placeholder={placeholder}
          name={name}
        />
        <div className='position-absolute translate-middle-y top-50 end-0 me-3 '>
          {submit_form && !submit_form_error ? (
            <KTIcon iconName='check' iconType='solid' className='fs-2hx text-success' />
          ) : submit_form ? (
            <KTIcon iconName='cross' iconType='solid' className='fs-2hx text-danger' />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='text-danger mt-2'>
        <ErrorMessage data-testid={name} name={name} />
      </div>
    </>
  )
}

export default InputComplete
