/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {EnableSidebar} from '../../../_metronic/layout/core'
import {Form, Formik} from 'formik'
import {VacationRequest, vacationSchema, inits} from './ReciboPagoHelper'
import {KTIcon} from '../../../_metronic/helpers'
import InputComplete from '../../components/inputs/InputComplete'
import Select2Complete from '../../components/inputs/Select2Complete'
import ReciboPagoList from './ReciboPagoList'
import {StartLoading, StopLoading} from '../../components/support/Script'
import {useAuth} from '../auth'
import {useFlash} from '../../components/support/Alerts'
const ReciboPagoMain: FC = () => {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
  const {setAlert} = useFlash()
  const [showDetail, setShowDetail] = useState(false)
  const [currentSchema] = useState(vacationSchema)
  const [initValues] = useState<VacationRequest>(inits)
  const [details, setDetails] = useState([])
  const {currentUser} = useAuth()
  const fechaConstruida = (mes: number, anio: number, dia: number) => {
    return new Date(anio, mes, dia)
  }
  const addZero = (num: number) => {
    if (num < 10) {
      return '0' + num
    }
    return num.toString()
  }
  const submit = (values: VacationRequest) => {
    StartLoading()
    const url = process.env.REACT_APP_URL_API_APOYO?.toString() ?? ''
    const fechaInicio = fechaConstruida(parseInt(values.mesInicio) - 1, values.anoInicio, 1)
    const fechaFin = fechaConstruida(parseInt(values.mesFinal), values.anoFinal, 0)
    const fechaInicioFormat =
      fechaInicio.getFullYear().toString() +
      addZero(fechaInicio.getMonth() + 1) +
      addZero(fechaInicio.getDate())
    const fechaFinFormat =
      fechaFin.getFullYear().toString() +
      addZero(fechaFin.getMonth() + 1) +
      addZero(fechaFin.getDate())
    if (url !== '') {
      fetch(`${url}/rh/recibo`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          empleado_id: currentUser?.id_api,
          fecha_inicio: fechaInicioFormat,
          fecha_fin: fechaFinFormat,
          token_app: process.env.REACT_APP_TOKEN_APOYO?.toString() ?? '123',
        }),
        headers: {
          'Content-type': 'text/plain',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          StopLoading()
          if (data.VALIDO !== true) {
            setAlert({type: 'danger', message: data.MENSAJE})
          } else {
            if (data.REGISTROS > 0) {
              setAlert({type: 'success', message: 'Consulta realizada correctamente'})
              setDetails(data.DATA)
              setShowDetail(true)
            } else {
              setAlert({type: 'warning', message: 'No se encontraron registros'})
            }
          }
        })
        .catch((e) => {
          setAlert({type: 'danger', message: e.message})
          StopLoading()
        })
    } else {
      setAlert({type: 'danger', message: 'URL_GATEWAY no definido'})
    }
  }
  const validate = (values: VacationRequest) => {
    setShowDetail(false)
    let errors = {anoInicio: '', mesInicio: '', anoFinal: '', mesFinal: ''}
    const fechaInicio = fechaConstruida(parseInt(values.mesInicio) - 1, values.anoInicio, 1)
    const fechaFin = fechaConstruida(parseInt(values.mesFinal), values.anoFinal, 0)
    if (fechaFin < fechaInicio) {
      errors.anoFinal = 'La Fecha Final debe ser mayor a la Fecha de Inicio'
      errors.mesFinal = 'La Fecha Final debe ser mayor a la Fecha de Inicio'
      return errors
    }
    return {}
  }
  return (
    <EnableSidebar>
      <h2>Recibo de Pago</h2>
      <Formik
        validationSchema={currentSchema}
        initialValues={initValues}
        onSubmit={submit}
        validate={validate}
      >
        {({errors, touched, values, setTouched}) => {
          return (
            <Form className='mx-auto pt-15 pb-10'>
              <div className='row'>
                <div className='col-lg-6 col-md-6'>
                  <Select2Complete
                    touched={touched}
                    errors={errors}
                    label={'Mes de Inicio'}
                    name={'mesInicio'}
                    placeholder='Mes de Inicio'
                    options={[
                      {label: 'Enero', value: '1'},
                      {label: 'Febrero', value: '2'},
                      {label: 'Marzo', value: '3'},
                      {label: 'Abril', value: '4'},
                      {label: 'Mayo', value: '5'},
                      {label: 'Junio', value: '6'},
                      {label: 'Julio', value: '7'},
                      {label: 'Agosto', value: '8'},
                      {label: 'Septiembre', value: '9'},
                      {label: 'Octubre', value: '10'},
                      {label: 'Noviembre', value: '11'},
                      {label: 'Diciembre', value: '12'},
                    ]}
                    required={true}
                  />
                </div>
                <div className='col-lg-6 col-md-6'>
                  <InputComplete
                    errors={errors}
                    touched={touched}
                    label={'Año'}
                    name={'anoInicio'}
                    placeholder='Año'
                    values={values}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 col-md-6'>
                  <Select2Complete
                    touched={touched}
                    errors={errors}
                    label={'Mes Final'}
                    name={'mesFinal'}
                    placeholder='Mes Fin'
                    options={[
                      {label: 'Enero', value: '1'},
                      {label: 'Febrero', value: '2'},
                      {label: 'Marzo', value: '3'},
                      {label: 'Abril', value: '4'},
                      {label: 'Mayo', value: '5'},
                      {label: 'Junio', value: '6'},
                      {label: 'Julio', value: '7'},
                      {label: 'Agosto', value: '8'},
                      {label: 'Septiembre', value: '9'},
                      {label: 'Octubre', value: '10'},
                      {label: 'Noviembre', value: '11'},
                      {label: 'Diciembre', value: '12'},
                    ]}
                    required={true}
                  />
                </div>
                <div className='col-lg-6 col-md-6'>
                  <InputComplete
                    errors={errors}
                    touched={touched}
                    label={'Año'}
                    name={'anoFinal'}
                    values={values}
                    placeholder='Año'
                  />
                </div>
              </div>
              <div className='d-flex flex-stack pt-15'>
                <button
                  type='submit'
                  className='btn btn-primary me-3'
                  style={{backgroundColor: '#182C59'}}
                >
                  <span className='indicator-label'>
                    <KTIcon iconName='search' />
                    Consultar
                  </span>
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
      {showDetail ? <ReciboPagoList details={details} /> : null}
    </EnableSidebar>
  )
}

export {ReciboPagoMain}
