import React, {FC} from 'react'
import {Text, View} from '@react-pdf/renderer'
import {JobRequest} from '../components/NuevoEmpleoHelper'
interface NuevoEmpleoHelperPDF {
  styles: any
  data?: JobRequest
}
const DatosFamiliares: FC<NuevoEmpleoHelperPDF> = ({styles, data}) => {
  return (
    <>
      <View style={{textAlign: 'left', marginTop: 10, marginLeft: 50}}>
        <Text>B. DATOS FAMILIARES</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Nombre del padre:</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>{data?.nombrePadre}</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>Teléfono:</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>{data?.telefonoPadre}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Lugar de trabajo:</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.lugarTrabajoPadre}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Nombre de la madre:</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>{data?.nombreMadre}</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>Teléfono:</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>{data?.telefonoMadre}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Lugar de trabajo:</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.lugarTrabajoMadre}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>No. de hermanos y profesión:</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.noHermanos + ' ' + data?.profesionHermanos}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>No. de personas que dependen de Ud.:</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.noPersonasDependencia}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Nombre del cónyuge</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.nombreConyuge}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Ocupación del cónyuge</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.ocupacionConyuge}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Nombre de la empresa donde trabaja su cónyuge</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.nombreTrabajoConyuge}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Dirección del trabajo de su conyuge</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>{data?.direccionTrabajoConyuge}</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>Teléfono:</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>{data?.telefonoTrabajoConyuge}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Cargo que ocupa su cónyuge</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.cargoTrabajoConyuge}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>No. de hijos:</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>{data?.noHijos}</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Edades de cada uno:</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>{data?.edadesHijos}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>En caso de emergencia llamar a:</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>{data?.contactoEmergencia}</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>Teléfono:</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>{data?.telefonoContactoEmergencia}</Text>
          </View>
        </View>
      </View>
    </>
  )
}
export default DatosFamiliares
