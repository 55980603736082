import * as Yup from 'yup'

export interface VacationRequest {
  anoInicio: number
  mesInicio: string
  anoFinal: number
  mesFinal: string
}

const vacationSchema = Yup.object({
  anoInicio: Yup.number().required('Campo requerido'),
  anoFinal: Yup.number().required('Campo requerido'),
  mesInicio: Yup.string().required('Campo requerido'),
  mesFinal: Yup.string().required('Campo requerido'),
})

const inits: VacationRequest = {
  anoInicio: new Date().getFullYear(),
  anoFinal: new Date().getFullYear(),
  mesInicio: new Date().getMonth().toString(),
  mesFinal: new Date().getMonth().toString(),
}

export {vacationSchema, inits}
