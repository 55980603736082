import React, {FC} from 'react'
import {Text, View} from '@react-pdf/renderer'
import {JobRequest} from '../components/NuevoEmpleoHelper'
interface NuevoEmpleoHelperPDF {
  styles: any
  data?: JobRequest
}
const ActividadesExtralaborales: FC<NuevoEmpleoHelperPDF> = ({styles, data}) => {
  return (
    <>
      <View style={{textAlign: 'left', marginTop: 10, marginLeft: 50}}>
        <Text>F. ACTIVIDADES EXTRALABORALES</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>
              Enumere las organizaciones sociales, culturales, deportivas, religiosas, sindicales u
              otras a las que pertenece o ha pertenecido:
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.organizaciones}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>
              Cuales son sus actividades recreativas y deportivas preferidas:
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.actividades}</Text>
          </View>
        </View>
      </View>
    </>
  )
}
export default ActividadesExtralaborales
