import {FC} from 'react'
import {EnableSidebar} from '../../../_metronic/layout/core'
import {KTIcon} from '../../../_metronic/helpers'
import {useAuth} from '../auth'
import TableRequest from '../../components/support/TableRequests'
import {useNavigate} from 'react-router-dom'
const Permisos: FC = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  return (
    <EnableSidebar>
      <h2>Permisos</h2>
      <TableRequest currentUser={currentUser} type='SOLICITAPERMISO' />
      <div className='d-flex flex-stack pt-15'>
        <button
          onClick={() => {
            navigate('/permisos/new', {replace: true})
          }}
          className='btn btn-primary me-3'
          style={{backgroundColor: '#182C59'}}
        >
          <span className='indicator-label'>
            <KTIcon iconName='plus' />
            Nuevo Permiso
          </span>
        </button>
      </div>
    </EnableSidebar>
  )
}

export default Permisos
