import React, {FC, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import ReciboPagoDetail from './ReciboPagoDetail'

export interface ReciboPagoListDetails {
  details: any
}

const ReciboPagoList: FC<ReciboPagoListDetails> = ({details}) => {
  const [row, setRow] = useState(details[0])
  const openModal: any = (obj: any) => {
    setRow(obj)
  }
  var contador = 0
  return (
    <>
      <table className='table table-bordered table-striped'>
        <thead>
          <tr>
            <td>
              <b>#</b>
            </td>
            <td>
              <b>Empresa</b>
            </td>
            <td>
              <b>Empleado</b>
            </td>
            <td>
              <b>Planilla</b>
            </td>
            <td>
              <b>Fecha</b>
            </td>
            <td>
              <b>Acción</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {details.map(function (detail: any, index: any) {
            contador++
            const fecha_inicio = new Date(detail.cabecera.fecha_inicio)
            const fecha_fin = new Date(detail.cabecera.fecha_fin)
            return (
              <tr key={index}>
                <td>{contador}</td>
                <td>{detail.cabecera.empresa}</td>
                <td>{detail.cabecera.empleado}</td>
                <td>{detail.cabecera.planilla}</td>
                <td>{fecha_inicio.toLocaleDateString() + '-' + fecha_fin.toLocaleDateString()}</td>
                <td>
                  <button
                    className='btn btn-warning btn-xs'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_recibo_detail'
                    onClick={() => {
                      openModal(detail)
                    }}
                  >
                    <span className='indicator-label'>
                      <KTIcon iconName='eye' />
                    </span>
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <ReciboPagoDetail row={row} />
    </>
  )
}

export default ReciboPagoList
