import React, {FC} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
interface obj_detail {
  obj: any
}
const TableRequestModal: FC<obj_detail> = ({obj}) => {
  if ('codigo' in obj) {
    return (
      <div className='modal fade' id='kt_modal_table_detail' aria-hidden='true'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content rounded'>
            <div className='modal-header'>
              <h5 className='modal-title'>Detalle de Solicitud {obj.codigo}</h5>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div>
              <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
                <div className='table-responsive'>
                  <table className='table table-bordered table-striped'>
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Fecha de Inicio</th>
                        <th>Usuario</th>
                        <th>Estatus</th>
                      </tr>
                    </thead>
                    <tbody>
                      {obj.proceso.detalle.map(function (item: any, index: number) {
                        return (
                          <tr key={`detalle_${index}`}>
                            <td>{item.nombre}</td>
                            <td align='center'>{item.fecha_inicio}</td>
                            <td>{item.usuario_creo}</td>
                            <td>{item.estatus}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='modal fade' id='kt_modal_table_detail' aria-hidden='true'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content rounded'>
            <div className='modal-header justify-content-end border-0 pb-0'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div>
              <div className='modal-body pt-0 pb-15 px-5 px-xl-20'></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default TableRequestModal
