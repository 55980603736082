import React, {FC} from 'react'
import {Text, View} from '@react-pdf/renderer'
import {JobRequest, calcularEdadEnAnios, formatearFecha} from '../components/NuevoEmpleoHelper'
interface NuevoEmpleoHelperPDF {
  styles: any
  data?: JobRequest
}
const InformacionPersonal: FC<NuevoEmpleoHelperPDF> = ({styles, data}) => {
  return (
    <>
      <View style={{textAlign: 'left', marginTop: 20, marginLeft: 50}}>
        <Text>A. INFORMACIÓN PERSONAL</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Nombre y apellidos completos:</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.nombreCompleto}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Lugar y fecha de Nacimiento</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>
              {data?.lugarNacimiento + ' ' + (data ? formatearFecha(data?.fechaNacimiento) : '')}
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>Edad:</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>
              {data ? calcularEdadEnAnios(data?.fechaNacimiento) : ''}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>No. de documento de identificación</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>{data?.numeroIdentificacion}</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>Extendida en:</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>{data?.extendidaIdentificacion}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Estado civil:</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>{data?.estadoCivil}</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>Nacionalidad:</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>{data?.nacionalidad}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Profesión u oficio:</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.profesion}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>No. afiliación Seguridad Social:</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>{data?.noSeguridadSocial}</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>No. identificación tributaria:</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>{data?.noIdentificacionTributaria}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Licencia de conducir:</Text>
          </View>
          <View style={{...styles.tableCol, width: '5%'}}>
            <Text style={styles.tableCell}>Tipo:</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>{data?.tipoLicencia}</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>Número</Text>
          </View>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>{data?.numeroLicencia}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Dirección Actual:</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.direccion}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>País:</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.paisResidencia}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Dirección de correo electrónico:</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.correoElectronico}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Teléfonos:</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.telefono}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Religión:</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.religion}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>Teléfono para localizarlo inmediatamente:</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.telefono}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>
              Cargo, posición o tipo de empleo al que usted aspira:
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.cargo}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>
              Cómo conoció sobre las oportunidades de trabajo en Servicios Quirúrgicos
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.comoNosConocio}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>
              Indique el departamento / área de trabajo en el que usted esta interesado, en orden de
              preferencia.
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.departamentoInteres}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>
              ¿Tiene algun familiar que trabaje para esta empresa?
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.familiar}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>
              ¿Tiene algun familiar que trabaje en alguna dependencia del Gobierno?
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.familiarGobierno}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>¿Tiene algun familiar que trabaje para el IGSS?</Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.familiarIGSS}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>
              ¿Ha solicitado empleo anteriormente en esta empresa?
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.solicitadoEmpleoAnteriormente}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '30%'}}>
            <Text style={styles.tableCell}>
              ¿Tiene usted alguna limitación geográfica para trabajar?
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '70%'}}>
            <Text style={styles.tableCell}>{data?.limitacionGeografica}</Text>
          </View>
        </View>
      </View>
    </>
  )
}
export default InformacionPersonal
