import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {ReciboPagoMain} from '../modules/recibo_pago/ReciboPagoMain'
import SolicitudCarta from '../modules/solicitud_carta/SolicitudCarta'
import Permisos from '../modules/permisos/Permisos'
import {NuevaSolicitudVacaciones} from '../modules/solicitud_vacaciones/NuevaSolicitudVacaciones'
import {SolicitudVacaciones} from '../modules/solicitud_vacaciones/SolicitudVacaciones'
import NuevoPermiso from '../modules/permisos/NuevoPermiso'
import NuevaSolicitudCarta from '../modules/solicitud_carta/NuevaSolicitudCarta'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const DevPage = lazy(() => import('../modules/apps/dev/DevPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='solicitud_vacaciones/*'
          element={
            <Routes>
              <Route index element={<SolicitudVacaciones />} />
              <Route path='list' element={<SolicitudVacaciones />} />
              <Route path='new' element={<NuevaSolicitudVacaciones />} />
            </Routes>
          }
        />
        <Route
          path='solicitud_carta/*'
          element={
            <Routes>
              <Route index element={<SolicitudCarta />} />
              <Route path='list' element={<SolicitudCarta />} />
              <Route path='new' element={<NuevaSolicitudCarta />} />
            </Routes>
          }
        />
        <Route
          path='permisos/*'
          element={
            <Routes>
              <Route index element={<Permisos />} />
              <Route path='list' element={<Permisos />} />
              <Route path='new' element={<NuevoPermiso />} />
            </Routes>
          }
        />
        <Route path='recibo_pago' element={<ReciboPagoMain />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/devs/*'
          element={
            <SuspensedView>
              <DevPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
