import React, {useEffect} from 'react'
import InputComplete from './InputComplete'
import {FieldArray} from 'formik'
import {KTIcon} from '../../../../_metronic/helpers'
type PasosProps = {
  errors: {}
  touched: {}
  values: any
  setTouched: any
  step: number
}
const Paso6 = ({errors, touched, values, setTouched, step}: PasosProps) => {
  useEffect(() => {
    setTouched({})
  }, [step, setTouched])
  return (
    <div className='row'>
      <FieldArray
        name='organizaciones'
        render={(arrayHelpers) => (
          <>
            <div className='col-md-12 col-lg-12'>
              <h3>
                Ingrese las organizaciones sociales, culturales, deportivas, religiosas, sindicales
                u otras a las que pertenece o ha pertenecido
                <button
                  type='button'
                  onClick={() => {
                    return arrayHelpers.push('')
                  }}
                  className='btn btn-sm btn-circle btn-icon btn-success btn-active-light-primary btn-custom position-relative'
                  style={{width: 25, height: 25, marginLeft: 20, marginBottom: 2}}
                >
                  <KTIcon iconName='plus' />
                </button>
              </h3>
            </div>
            {values.organizaciones.map((organizaciones: [], index: number) => {
              return (
                <>
                  <div className='col-md-6 col-lg-6'>
                    <h5>
                      Organización No. {index + 1}
                      <button
                        type='button'
                        onClick={() => {
                          return arrayHelpers.remove(index)
                        }}
                        className='btn btn-sm btn-circle btn-icon btn-danger btn-active-light-primary btn-custom position-relative'
                        style={{width: 25, height: 25, marginLeft: 20, marginBottom: 2}}
                      >
                        <KTIcon iconName='minus' />
                      </button>
                    </h5>

                    <InputComplete
                      errors={errors}
                      touched={touched}
                      label={''}
                      name={`organizaciones[${index}]`}
                      placeholder=''
                      required={false}
                    />
                  </div>
                </>
              )
            })}
          </>
        )}
      />
      <FieldArray
        name='actividades'
        render={(arrayHelpers) => (
          <>
            <div className='col-md-12 col-lg-12'>
              <h3>
                Cuales son sus actividades recreativas y deportivas preferidas
                <button
                  type='button'
                  onClick={() => {
                    return arrayHelpers.push('')
                  }}
                  className='btn btn-sm btn-circle btn-icon btn-success btn-active-light-primary btn-custom position-relative'
                  style={{width: 25, height: 25, marginLeft: 20, marginBottom: 2}}
                >
                  <KTIcon iconName='plus' />
                </button>
              </h3>
            </div>
            {values.actividades.map((actividades: [], index: number) => {
              return (
                <>
                  <div className='col-md-6 col-lg-6'>
                    <h5>
                      Actividad No. {index + 1}
                      <button
                        type='button'
                        onClick={() => {
                          return arrayHelpers.remove(index)
                        }}
                        className='btn btn-sm btn-circle btn-icon btn-danger btn-active-light-primary btn-custom position-relative'
                        style={{width: 25, height: 25, marginLeft: 20, marginBottom: 2}}
                      >
                        <KTIcon iconName='minus' />
                      </button>
                    </h5>

                    <InputComplete
                      errors={errors}
                      touched={touched}
                      label={''}
                      name={`actividades[${index}]`}
                      placeholder=''
                      required={false}
                    />
                  </div>
                </>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default Paso6
