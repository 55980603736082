import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {HorizontalSteps} from './components/HorizontalSteps'
const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: ``,
      }}
    >
      {/* begin::Content */}
      <div
        className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'
        style={{backgroundColor: '#172C59'}}
      >
        {/* begin::Wrapper */}
        <div className='w-lg-1200px w-md-1200px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}

const NuevoEmpleoPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='*' element={<HorizontalSteps />} />
      <Route index element={<HorizontalSteps />} />
    </Route>
  </Routes>
)

export {NuevoEmpleoPage}
