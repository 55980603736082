import React, {FC} from 'react'
import {Text, View} from '@react-pdf/renderer'
import {JobRequest, formatearFecha, formatoSiNo} from '../components/NuevoEmpleoHelper'
interface NuevoEmpleoHelperPDF {
  styles: any
  data?: JobRequest
}
const CondicionesTrabajo: FC<NuevoEmpleoHelperPDF> = ({styles, data}) => {
  return (
    <>
      <View style={{textAlign: 'left', marginTop: 10, marginLeft: 50}}>
        <Text>H. CONDICIONES DE TRABAJO</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>¿Está dispuesto a viajar como parte de su trabajo?</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{formatoSiNo(data?.dispuestoAViajar)}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>¿Posee pasaporte vigente?</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>{formatoSiNo(data?.pasaporteVigente)}</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>Número:</Text>
          </View>
          <View style={{...styles.tableCol, width: '20%'}}>
            <Text style={styles.tableCell}>{data?.numPasaporte}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>¿Tiene limitaciones de horario?</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{formatoSiNo(data?.limitacionesHorario)}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Especifique:</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.detalleLimitacionesHorario}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Tiene alguna discapacidad física:</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.discapacidadFisica}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Expectativa salarial (mensual):</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.expectativaSalaria}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>
              Fecha en que puede iniciar en su nuevo trabajo con nosotros:
            </Text>
          </View>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>
              {data && data?.fechaInicioTrabajo !== undefined
                ? formatearFecha(data?.fechaInicioTrabajo)
                : ''}
            </Text>
          </View>
        </View>
      </View>
    </>
  )
}
export default CondicionesTrabajo
