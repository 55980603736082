import {FC} from 'react'
import {EnableSidebar} from '../../../_metronic/layout/core'
import {KTIcon} from '../../../_metronic/helpers'
import {useAuth} from '../auth'
import TableRequest from '../../components/support/TableRequests'
import {useNavigate} from 'react-router-dom'
const SolicitudCarta: FC = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  return (
    <EnableSidebar>
      <h2>Solicitudes de Cartas</h2>
      <TableRequest currentUser={currentUser} type='RHCARTA' />
      <div className='d-flex flex-stack pt-15'>
        <button
          onClick={() => {
            navigate('/solicitud_carta/new', {replace: true})
          }}
          className='btn  btn-primary me-3'
          style={{backgroundColor: '#182C59'}}
        >
          <span className='indicator-label'>
            <KTIcon iconName='plus' />
            Nuevo Carta
          </span>
        </button>
      </div>
    </EnableSidebar>
  )
}

export default SolicitudCarta
