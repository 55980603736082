import React, {FC} from 'react'
import {Text, View} from '@react-pdf/renderer'
import {JobRequest} from '../components/NuevoEmpleoHelper'
interface NuevoEmpleoHelperPDF {
  styles: any
  data?: JobRequest
}
const InformacionSocioeconomica: FC<NuevoEmpleoHelperPDF> = ({styles, data}) => {
  return (
    <>
      <View style={{textAlign: 'left', marginTop: 10, marginLeft: 50}}>
        <Text>C. INFORMACIÓN SOCIOECONÓMICA</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Tipo de Vivienda:</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.tipoVivienda}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>Tiene vehículo propio:</Text>
          </View>
          <View style={{...styles.tableCol, width: '10%'}}>
            <Text style={styles.tableCell}>{data?.vehiculoPropio}</Text>
          </View>
          <View style={{...styles.tableCol, width: '15%'}}>
            <Text style={styles.tableCell}>Modelo (año):</Text>
          </View>
          <View style={{...styles.tableCol, width: '35%'}}>
            <Text style={styles.tableCell}>{data?.modeloVehiculo}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '40%'}}>
            <Text style={styles.tableCell}>De donde procede este ingreso:</Text>
          </View>
          <View style={{...styles.tableCol, width: '60%'}}>
            <Text style={styles.tableCell}>{data?.origenOtrosIngresos}</Text>
          </View>
        </View>
      </View>
    </>
  )
}
export default InformacionSocioeconomica
