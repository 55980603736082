import React, {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
interface AlertInterface {
  type: string
  message: string
}
interface AlertInterfaceContext {
  alert: AlertInterface
  setAlert: Function
}
const initsFlash = {
  alert: {type: '', message: ''},
  setAlert: () => {},
}
const FlashMessageAlert = createContext<AlertInterfaceContext>(initsFlash)
const useFlash = () => {
  return useContext(FlashMessageAlert)
}
const Alerts: FC = () => {
  const {alert} = useFlash()
  const alertObj = alert
  const classNameAlert = 'alert alert-dismissible fade show alert-' + alertObj.type
  return (
    <>
      {alertObj.message && alertObj.type ? (
        <div className={classNameAlert} role='alert'>
          {alertObj.message}
        </div>
      ) : null}
    </>
  )
}
const FlashMessageContext: FC<WithChildren> = ({children}) => {
  const [alert, setAlert] = useState<AlertInterface>({type: '', message: ''})
  return (
    <FlashMessageAlert.Provider value={{alert, setAlert}}>{children}</FlashMessageAlert.Provider>
  )
}
export default Alerts
export {FlashMessageContext, useFlash}
