import {useFormikContext} from 'formik'
import React, {FC} from 'react'
type FileInputType = {
  label: string
  name: string
  required?: boolean
}
const FileInput: FC<FileInputType> = ({label, name, required = true}) => {
  const {setFieldValue} = useFormikContext() ?? {}
  return (
    <>
      {label !== '' ? (
        <label className={'form-label ' + (required ? 'required' : '')}>{label}</label>
      ) : null}
      <div className='position-relative'>
        <input
          id={name}
          name={name}
          type='file'
          onChange={(event) => {
            setFieldValue(
              name,
              event.currentTarget != null &&
                event.currentTarget.files &&
                event.currentTarget.files.length > 0
                ? event.currentTarget.files[0]
                : null
            )
          }}
        />
      </div>
    </>
  )
}
export default FileInput
