const StartLoading = () => {
  const splash = document.getElementById('splash-screen')
  const kt_body = document.getElementById('kt_body')
  if (splash !== null) {
    splash.style.display = ''
  }
  if (kt_body) {
    kt_body.classList.remove('aside-fixed')
    kt_body.classList.remove('aside-enabled')
    kt_body.classList.remove('sidebar-enabled')
    kt_body.classList.add('page-loading')
  }
}
const StopLoading = () => {
  const splash = document.getElementById('splash-screen')
  const kt_body = document.getElementById('kt_body')
  if (splash !== null) {
    splash.style.display = 'none'
  }
  if (kt_body) {
    kt_body.classList.remove('page-loading')
    kt_body.classList.add('aside-fixed')
    kt_body.classList.add('aside-enabled')
    kt_body.classList.add('sidebar-enabled')
  }
}

export {StartLoading, StopLoading}
