import {FC} from 'react'
import './Avatar.css'
import {HeaderUserMenu} from '../../../partials'
import {useAuth} from '../../../../app/modules/auth'

const Topbar: FC = () => {
  const {currentUser} = useAuth()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        <div className='d-flex align-items-center ms-lg-5' id='kt_header_user_menu_toggle'>
          <div
            className='btn btn-active-light d-flex align-items-center bg-hover-light py-2 px-2 px-md-3'
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <div className='d-none d-md-flex flex-column align-items-end justify-content-center me-2'>
              <span className='text-muted fs-7 fw-bold lh-1 mb-2'>Hola</span>
              <span className='text-dark fs-base fw-bolder lh-1'>
                {currentUser?.first_name} {currentUser?.last_name}
              </span>
            </div>

            <div className='avatar'>{currentUser?.first_name.substring(0, 2).toUpperCase()}</div>
          </div>
          <HeaderUserMenu />
        </div>
      </div>
    </div>
  )
}

export {Topbar}
