import React, {useEffect} from 'react'
import InputComplete from './InputComplete'
import Select2Complete from './Select2Complete'
type Paso2Props = {
  errors: {}
  touched: {}
  values: any
  setTouched: any
  step: number
}
const Paso2 = ({errors, touched, values, setTouched, step}: Paso2Props) => {
  useEffect(() => {
    setTouched({})
  }, [step, setTouched])
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Datos Familiares</h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12 col-md-12'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Nombre del Padre'}
              name={'nombrePadre'}
              placeholder='Nombre Completo del Padre'
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Telefono del Padre'}
              name={'telefonoPadre'}
              placeholder='Telefono del Padre'
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Lugar de trabajo del Padre'}
              name={'lugarTrabajoPadre'}
              placeholder='Lugar de trabajo del Padre'
              required={false}
            />
          </div>
          <div className='col-lg-12 col-md-12'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Nombre de la Madre'}
              name={'nombreMadre'}
              placeholder='Nombre Completo de la madre'
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Telefono de la madre'}
              name={'telefonoMadre'}
              placeholder='Telefono de la madre'
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Lugar de trabajo de  la madre'}
              name={'lugarTrabajoMadre'}
              placeholder='Lugar de trabajo de la madre'
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Número de Hermanos'}
              name={'noHermanos'}
              placeholder='Número de Hermanos'
              required={true}
            />
          </div>
          {'noHermanos' in values && values.noHermanos > 0 ? (
            <div className='col-lg-6 col-md-6'>
              <InputComplete
                errors={errors}
                touched={touched}
                label={'Profesión de Hermanos'}
                name={'profesionHermanos'}
                placeholder='Profesión de Hermanos'
                required={'noHermanos' in values && values.noHermanos > 0 ? true : false}
              />
            </div>
          ) : (
            <></>
          )}

          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Número de personas que dependen de usted'}
              name={'noPersonasDependencia'}
              placeholder='Número de personas que dependen de usted'
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'¿Tiene conyuge?'}
              name={'tieneConyuge'}
              placeholder='Tiene Conyuge?'
              options={[
                {label: 'Si', value: 'Si'},
                {label: 'No', value: 'No'},
              ]}
              required={false}
            />
          </div>
          {'tieneConyuge' in values && values.tieneConyuge === 'Si' ? (
            <>
              <div className='col-lg-6 col-md-6'>
                <InputComplete
                  errors={errors}
                  touched={touched}
                  label={'Nombre de Conyuge'}
                  name={'nombreConyuge'}
                  placeholder='Nombre de Conyuge'
                  required={true}
                />
              </div>
              <div className='col-lg-6 col-md-6'>
                <InputComplete
                  errors={errors}
                  touched={touched}
                  label={'Ocupación de Conyuge'}
                  name={'ocupacionConyuge'}
                  placeholder='Ocupación de Conyuge'
                  required={true}
                />
              </div>
              <div className='col-lg-6 col-md-6'>
                <InputComplete
                  errors={errors}
                  touched={touched}
                  label={'Nombre de la empresa donde trabaja el Conyuge'}
                  name={'nombreTrabajoConyuge'}
                  placeholder='Nombre de la empresa donde trabaja el Conyuge'
                  required={true}
                />
              </div>
              <div className='col-lg-6 col-md-6'>
                <InputComplete
                  errors={errors}
                  touched={touched}
                  label={'Dirección de la empresa donde trabaja el Conyuge'}
                  name={'direccionTrabajoConyuge'}
                  placeholder='Dirección de la empresa donde trabaja el Conyuge'
                  required={true}
                />
              </div>
              <div className='col-lg-6 col-md-6'>
                <InputComplete
                  errors={errors}
                  touched={touched}
                  label={'Teléfono de la empresa donde trabaja el Conyuge'}
                  name={'telefonoTrabajoConyuge'}
                  placeholder='Teléfono de la empresa donde trabaja el Conyuge'
                  required={true}
                />
              </div>
              <div className='col-lg-6 col-md-6'>
                <InputComplete
                  errors={errors}
                  touched={touched}
                  label={'Cargo que ocupa su conyuge'}
                  name={'cargoTrabajoConyuge'}
                  placeholder='Cargo que ocupa su conyuge'
                  required={true}
                />
              </div>
            </>
          ) : null}
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Número de hijos'}
              name={'noHijos'}
              placeholder='Número de hijos'
              required={true}
            />
          </div>
          {'noHijos' in values && values.noHijos > 0 ? (
            <div className='col-lg-6 col-md-6'>
              <InputComplete
                errors={errors}
                touched={touched}
                label={'Edades de los hijos'}
                name={'edadesHijos'}
                placeholder='Edades de los hijos'
                required={'noHijos' in values && values.noHijos > 0 ? true : false}
              />
            </div>
          ) : (
            <></>
          )}

          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Ingrese el nombre de su contacto de emergencia'}
              name={'contactoEmergencia'}
              placeholder='Ingrese el nombre de su contacto de emergencia'
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Ingrese el teléfono de su contacto de emergencia'}
              name={'telefonoContactoEmergencia'}
              placeholder='Ingrese el teléfono de su contacto de emergencia'
              required={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Paso2}
