import React, {useEffect} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import InputComplete from './InputComplete'
import {FieldArray} from 'formik'
import InputDatePicker from './InputDatePicker'
import Select2Complete from './Select2Complete'
type Paso5Props = {
  errors: {}
  touched: {}
  values: any
  setTouched: any
  step: number
}
const Paso5 = ({errors, touched, values, setTouched, step}: Paso5Props) => {
  useEffect(() => {
    setTouched({})
  }, [step, setTouched])
  return (
    <div className='w-100'>
      <FieldArray
        name='experienciaLaboral'
        render={(arrayHelpers) => (
          <div className='pb-10 pb-lg-15'>
            <div className='row'>
              <div className='col-md-6 col-lg-10'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                  Experiencia Laboral
                </h2>
              </div>
              <div className='col-md-2 col-lg-2'>
                <button
                  type='button'
                  onClick={() => {
                    return arrayHelpers.push({
                      nombre: '',
                      telefono: '',
                      direccion: '',
                      giro: '',
                      fechaIngreso: new Date('2000'),
                      fechaSalida: '',
                      cargoInicial: '',
                      cargoFinal: '',
                      funciones: '',
                      nombreJefe: '',
                      noPersonasACargo: 0,
                      sueldoInicial: 0,
                      sueldoFinal: 0,
                      numeroSueldosAlAnio: 14,
                      otrasPrestaciones: '',
                      motivoRetiro: '',
                      masGusto: '',
                      menosGusto: '',
                      pedirReferencias: '',
                    })
                  }}
                  className='btn btn-sm btn-circle btn-icon btn-success btn-active-light-primary btn-custom position-relative'
                >
                  <KTIcon iconName='plus' />
                </button>
              </div>
            </div>
            <br />
            {values.experienciaLaboral.map((experienciaLaboral: {}, index: number) => {
              return (
                <div key={index}>
                  <div className='fv-row'>
                    <div className='row'>
                      <div className='col-lg-12 col-md-12'>
                        <div className='row'>
                          <div className='col-md-10 col-lg-10'>
                            <h3>Experiencia No. {index + 1}</h3>
                          </div>
                          <div className='col-md-2 col-lg-2'>
                            <button
                              type='button'
                              onClick={() => {
                                arrayHelpers.remove(index)
                              }}
                              className='btn btn-sm btn-circle btn-icon btn-danger btn-active-light-primary  '
                            >
                              <KTIcon iconName='minus' />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Nombre de la empresa'}
                          name={`experienciaLaboral[${index}].nombre`}
                          placeholder='Nombre de la empresa'
                          required={true}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Teléfono de la empresa'}
                          name={`experienciaLaboral[${index}].telefono`}
                          placeholder='Teléfono de la empresa'
                          required={true}
                        />
                      </div>
                      <div className='col-lg-12 col-md-12'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Dirección de la empresa'}
                          name={`experienciaLaboral[${index}].direccion`}
                          placeholder='Dirección de la empresa'
                          required={true}
                        />
                      </div>
                      <div className='col-lg-12 col-md-12'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Giro de la empresa'}
                          name={`experienciaLaboral[${index}].giro`}
                          placeholder='Giro de la empresa'
                          required={true}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputDatePicker
                          errors={errors}
                          touched={touched}
                          label={'Fecha de Ingreso'}
                          name={`experienciaLaboral[${index}].fechaIngreso`}
                          required={true}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputDatePicker
                          errors={errors}
                          touched={touched}
                          label={'Fecha de Salida'}
                          name={`experienciaLaboral[${index}].fechaSalida`}
                          required={false}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Cargo Inicial'}
                          name={`experienciaLaboral[${index}].cargoInicial`}
                          required={true}
                          placeholder={'Cargo Inicial'}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Cargo Final'}
                          name={`experienciaLaboral[${index}].cargoFinal`}
                          placeholder={'Cargo Final'}
                        />
                      </div>
                      <div className='col-lg-12 col-md-12'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Funciones desempeñadas'}
                          name={`experienciaLaboral[${index}].funciones`}
                          required={true}
                          placeholder={'Funciones desempeñadas'}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Jefe inmediato'}
                          name={`experienciaLaboral[${index}].nombreJefe`}
                          required={true}
                          placeholder={'Jefe inmediato'}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Número de personas a cargo'}
                          name={`experienciaLaboral[${index}].noPersonasACargo`}
                          required={true}
                          placeholder={'Número de personas a cargo'}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Sueldo Inicial'}
                          name={`experienciaLaboral[${index}].sueldoInicial`}
                          required={true}
                          placeholder={'Sueldo Inicial'}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Sueldo Final'}
                          name={`experienciaLaboral[${index}].sueldoFinal`}
                          required={true}
                          placeholder={'Sueldo Final'}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Número de sueldos al año'}
                          name={`experienciaLaboral[${index}].numeroSueldosAlAnio`}
                          required={true}
                          placeholder={'Número de sueldos al año'}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Otras prestaciones'}
                          name={`experienciaLaboral[${index}].otrasPrestaciones`}
                          required={false}
                          placeholder={'Otras prestaciones'}
                        />
                      </div>
                      <div className='col-lg-12 col-md-12'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Motivo de Retiro'}
                          name={`experienciaLaboral[${index}].motivoRetiro`}
                          required={false}
                          placeholder={'Motivo de Retiro'}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Lo que más le gusto de la empresa'}
                          name={`experienciaLaboral[${index}].masGusto`}
                          required={true}
                          placeholder={'Lo que menos le gusto de la empresa'}
                        />
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <InputComplete
                          errors={errors}
                          touched={touched}
                          label={'Lo que menos le gusto de la empresa'}
                          name={`experienciaLaboral[${index}].menosGusto`}
                          required={true}
                          placeholder={'Lo que menos le gusto de la empresa'}
                        />
                      </div>
                      <div className='col-lg-12 col-md-12'>
                        <Select2Complete
                          touched={touched}
                          errors={errors}
                          label={'¿Podemos pedir referencias?'}
                          name={`experienciaLaboral[${index}].pedirReferencias`}
                          placeholder='¿Podemos pedir referencias?'
                          options={[
                            {label: 'Si', value: 'Si'},
                            {label: 'No', value: 'No'},
                          ]}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      />
    </div>
  )
}

export {Paso5}
