import React, {useEffect} from 'react'
import InputComplete from './InputComplete'
import Select2Complete from './Select2Complete'
type Paso3Props = {
  errors: {}
  touched: {}
  values: any
  setTouched: any
  step: number
}
const Paso3 = ({errors, touched, values, setTouched, step}: Paso3Props) => {
  useEffect(() => {
    setTouched({})
  }, [step, setTouched])
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Información Socioeconómica
        </h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12 col-md-12'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'Seleccione su tipo de vivienda'}
              name={'tipoVivienda'}
              placeholder='Tiene Conyuge?'
              options={[
                {label: 'Propio', value: 'Propio'},
                {label: 'Alquilado', value: 'Alquilado'},
                {label: 'De sus padres', value: 'De sus padres'},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'Posee vehiculo propio?'}
              name={'vehiculoPropio'}
              placeholder='Posee vehiculo propio?'
              options={[
                {label: 'Si', value: 'Si'},
                {label: 'No', value: 'No'},
              ]}
              required={true}
            />
          </div>
          {'vehiculoPropio' in values && values.vehiculoPropio === 'Si' ? (
            <div className='col-lg-6 col-md-6'>
              <InputComplete
                errors={errors}
                touched={touched}
                label={'Ingrese el modelo del vehiculo'}
                name={'modeloVehiculo'}
                placeholder='Ingrese el modelo del vehiculo'
                required={true}
              />
            </div>
          ) : null}
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'Tiene otros ingresos?'}
              name={'otrosIngresos'}
              placeholder='Tiene otros ingresos?'
              options={[
                {label: 'Si', value: 'Si'},
                {label: 'No', value: 'No'},
              ]}
              required={true}
            />
          </div>
          {'otrosIngresos' in values && values.otrosIngresos === 'Si' ? (
            <>
              <div className='col-lg-6 col-md-6'>
                <InputComplete
                  errors={errors}
                  touched={touched}
                  label={'Seleccione el monto de los otros ingresos'}
                  name={'montoOtrosIngresos'}
                  placeholder='Seleccione el monto de los otros ingresos'
                  required={true}
                />
              </div>
              <div className='col-lg-6 col-md-6'>
                <InputComplete
                  errors={errors}
                  touched={touched}
                  label={'Ingrese el origen de los otros ingresos'}
                  name={'origenOtrosIngresos'}
                  placeholder='Ingrese el origen de los otros ingresos'
                  required={true}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export {Paso3}
