import React, {FC} from 'react'
import {Page, Text, View, Document, StyleSheet, PDFViewer} from '@react-pdf/renderer'
import InformacionPersonal from '../pdf_helper/InformacionPersonal'
import DatosFamiliares from '../pdf_helper/DatosFamiliares'
import InformacionSocioeconomica from '../pdf_helper/InformacionSocioeconomica'
import Educacion from '../pdf_helper/Educacion'
import ExperienciaLaboral from '../pdf_helper/ExperienciaLaboral'
import ActividadesExtralaborales from '../pdf_helper/ActividadesExtralaborales'
import Salud from '../pdf_helper/Salud'
import CondicionesTrabajo from '../pdf_helper/CondicionesTrabajo'
import ReferenciasPersonales from '../pdf_helper/ReferenciasPersonales'
import ReferenciasLaborales from '../pdf_helper/ReferenciasLaborales'
import {JobRequest} from './NuevoEmpleoHelper'
interface PdfSolicitudInterface {
  obj?: JobRequest
}
const PdfSolicitud: FC<PdfSolicitudInterface> = ({obj}) => {
  return (
    <>
      <PDFViewer>
        <BaseDocumento obj={obj} />
      </PDFViewer>
    </>
  )
}
const BaseDocumento: FC<PdfSolicitudInterface> = ({obj}) => {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#0000',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableCol: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCell: {
      marginLeft: 5,
      marginTop: 5,
      fontSize: 10,
      textAlign: 'justify',
    },
  })
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={{textAlign: 'center'}}>
          <Text>RECLUTAMIENTO Y SELECCIÓN</Text>
        </View>
        <View
          style={{
            textAlign: 'center',
            marginTop: 10,
          }}
        >
          <Text>INFORMACIÓN DEL ASPIRANTE</Text>
        </View>
        <InformacionPersonal styles={styles} data={obj} />
        <DatosFamiliares styles={styles} data={obj} />
        <InformacionSocioeconomica styles={styles} data={obj} />
        <Educacion styles={styles} data={obj} />
        <ExperienciaLaboral styles={styles} data={obj} />
        <ActividadesExtralaborales styles={styles} data={obj} />
        <Salud styles={styles} data={obj} />
        <CondicionesTrabajo styles={styles} data={obj} />
        <ReferenciasPersonales styles={styles} data={obj} />
        <ReferenciasLaborales styles={styles} data={obj} />
      </Page>
    </Document>
  )
}
export {BaseDocumento, PdfSolicitud}
