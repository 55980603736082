import React, {FC} from 'react'
import {Text, View} from '@react-pdf/renderer'
import {JobRequest, ReferenciaLaboral} from '../components/NuevoEmpleoHelper'
interface NuevoEmpleoHelperPDF {
  styles: any
  data?: JobRequest
}
const ReferenciasLaborales: FC<NuevoEmpleoHelperPDF> = ({styles, data}) => {
  return (
    <>
      <View style={{textAlign: 'left', marginTop: 10, marginLeft: 50}}>
        <Text>J. REFERENCIAS LABORALES</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{...styles.tableCol, width: '25%'}}>
            <Text style={{...styles.tableCell, textAlign: 'center'}}>Nombre</Text>
          </View>
          <View style={{...styles.tableCol, width: '25%'}}>
            <Text style={{...styles.tableCell, textAlign: 'center'}}>Teléfono (s)</Text>
          </View>
          <View style={{...styles.tableCol, width: '25%'}}>
            <Text style={{...styles.tableCell, textAlign: 'center'}}>Empresa</Text>
          </View>
          <View style={{...styles.tableCol, width: '25%'}}>
            <Text style={{...styles.tableCell, textAlign: 'center'}}>Puesto</Text>
          </View>
        </View>
        {data?.referenciasLaborales.map(function (value: ReferenciaLaboral, index: number) {
          return (
            <View style={styles.tableRow} key={index + '_ref_lab'}>
              <View style={{...styles.tableCol, width: '25%'}}>
                <Text style={{...styles.tableCell, textAlign: 'center'}}>{value.nombre}</Text>
              </View>
              <View style={{...styles.tableCol, width: '25%'}}>
                <Text style={{...styles.tableCell, textAlign: 'center'}}>{value.telefono}</Text>
              </View>
              <View style={{...styles.tableCol, width: '25%'}}>
                <Text style={{...styles.tableCell, textAlign: 'center'}}>{value.empresa}</Text>
              </View>
              <View style={{...styles.tableCol, width: '25%'}}>
                <Text style={{...styles.tableCell, textAlign: 'center'}}>{value.puesto}</Text>
              </View>
            </View>
          )
        })}
      </View>
    </>
  )
}
export default ReferenciasLaborales
