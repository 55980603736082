import React, {FC} from 'react'
import {Text, View} from '@react-pdf/renderer'
import {JobRequest, LaboralExperience, formatearFecha} from '../components/NuevoEmpleoHelper'
interface NuevoEmpleoHelperPDF {
  styles: any
  data?: JobRequest
}
const ExperienciaLaboral: FC<NuevoEmpleoHelperPDF> = ({styles, data}) => {
  return (
    <>
      <View style={{textAlign: 'left', marginTop: 10, marginLeft: 50}}>
        <Text>E. EXPERIENCIA LABORAL</Text>
      </View>
      <View style={styles.table}>
        {data?.experienciaLaboral.map(function (value: LaboralExperience, index: number) {
          return (
            <View key={index + '_exp_lab'}>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Nombre de la empresa:</Text>
                </View>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>{value.nombre}</Text>
                </View>
                <View style={{...styles.tableCol, width: '20%'}}>
                  <Text style={styles.tableCell}>Teléfono:</Text>
                </View>
                <View style={{...styles.tableCol, width: '20%'}}>
                  <Text style={styles.tableCell}>{value.telefono}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Dirección</Text>
                </View>
                <View style={{...styles.tableCol, width: '70%'}}>
                  <Text style={styles.tableCell}>{value.direccion}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Giro de la empresa:</Text>
                </View>
                <View style={{...styles.tableCol, width: '70%'}}>
                  <Text style={styles.tableCell}>{value.giro}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Fecha de ingreso:</Text>
                </View>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>{formatearFecha(value.fechaIngreso)}</Text>
                </View>
                <View style={{...styles.tableCol, width: '20%'}}>
                  <Text style={styles.tableCell}>Fecha de salida:</Text>
                </View>
                <View style={{...styles.tableCol, width: '20%'}}>
                  <Text style={styles.tableCell}>
                    {value.fechaSalida ? formatearFecha(value.fechaSalida) : ''}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Cargo Inicial:</Text>
                </View>
                <View style={{...styles.tableCol, width: '70%'}}>
                  <Text style={styles.tableCell}>{value.cargoInicial}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Ultimo cargo ocupado:</Text>
                </View>
                <View style={{...styles.tableCol, width: '70%'}}>
                  <Text style={styles.tableCell}>{value.cargoFinal}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '30%'}}>
                  <Text style={styles.tableCell}>Funciones principales desempeñadas:</Text>
                </View>
                <View style={{...styles.tableCol, width: '70%'}}>
                  <Text style={styles.tableCell}>{value.funciones}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '40%'}}>
                  <Text style={styles.tableCell}>Nombre de su jefe inmediato:</Text>
                </View>
                <View style={{...styles.tableCol, width: '60%'}}>
                  <Text style={styles.tableCell}>{value.nombreJefe}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '40%'}}>
                  <Text style={styles.tableCell}>No. de personas a su cargo:</Text>
                </View>
                <View style={{...styles.tableCol, width: '60%'}}>
                  <Text style={styles.tableCell}>{value.noPersonasACargo}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '40%'}}>
                  <Text style={styles.tableCell}>Sueldo Inicial:</Text>
                </View>
                <View style={{...styles.tableCol, width: '20%'}}>
                  <Text style={styles.tableCell}>{value.sueldoInicial}</Text>
                </View>
                <View style={{...styles.tableCol, width: '20%'}}>
                  <Text style={styles.tableCell}>Sueldo Final:</Text>
                </View>
                <View style={{...styles.tableCol, width: '20%'}}>
                  <Text style={styles.tableCell}>{value.sueldoFinal}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '40%'}}>
                  <Text style={styles.tableCell}>
                    Número de sueldos mensuales recibidos en el año (bono 14, aguinaldo, otros):
                  </Text>
                </View>
                <View style={{...styles.tableCol, width: '60%'}}>
                  <Text style={styles.tableCell}>{value.numeroSueldosAlAnio}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '40%'}}>
                  <Text style={styles.tableCell}>Otras prestaciones:</Text>
                </View>
                <View style={{...styles.tableCol, width: '60%'}}>
                  <Text style={styles.tableCell}>{value.otrasPrestaciones}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '40%'}}>
                  <Text style={styles.tableCell}>Motivo de su retiro:</Text>
                </View>
                <View style={{...styles.tableCol, width: '60%'}}>
                  <Text style={styles.tableCell}>{value.motivoRetiro}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '40%'}}>
                  <Text style={styles.tableCell}>Lo que más le gusta de la empresa:</Text>
                </View>
                <View style={{...styles.tableCol, width: '60%'}}>
                  <Text style={styles.tableCell}>{value.masGusto}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '40%'}}>
                  <Text style={styles.tableCell}>Lo que menos le gusta de la empresa:</Text>
                </View>
                <View style={{...styles.tableCol, width: '60%'}}>
                  <Text style={styles.tableCell}>{value.menosGusto}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{...styles.tableCol, width: '40%'}}>
                  <Text style={styles.tableCell}>Podemos pedir referencias:</Text>
                </View>
                <View style={{...styles.tableCol, width: '60%'}}>
                  <Text style={styles.tableCell}>{value.pedirReferencias}</Text>
                </View>
              </View>
            </View>
          )
        })}
      </View>
    </>
  )
}
export default ExperienciaLaboral
