import React, {useMemo, useEffect, useState} from 'react'
import InputComplete from './InputComplete'
import InputDatePicker from './InputDatePicker'
import Select2Complete, {OptionsType} from './Select2Complete'
import countryList from 'react-select-country-list'

type PasoProps = {
  errors: {}
  touched: {}
  values: any
  setTouched: any
  step: number
}
const Paso1 = ({errors, touched, values, setTouched, step}: PasoProps) => {
  const [lista_empleos, setListaEmpleos] = useState<OptionsType[]>([])
  useEffect(() => {
    setTouched({})
    const url = process.env.REACT_APP_URL_API_APOYO?.toString() ?? ''
    if (url !== '') {
      fetch(`${url}/rh/data`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          planillaid: '',
          fecha_inicio: '',
          fecha_fin: '',
          valor_texto: 'catalogo',
          valor_numero: '',
          valor_texto2: '',
          valor_numero2: '',
          proyecto_id: '',
          token_app: process.env.REACT_APP_TOKEN_APOYO?.toString() ?? '123',
          query: 'api.con_catalogo_plaza',
        }),
        headers: {
          'Content-type': 'text/plain',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.VALIDO === true) {
            let lista_asignar: OptionsType[] = [{value: '', label: '[Seleccione una opcion]'}]
            const registros = data.DATA.datos.registro
            if (registros !== undefined) {
              for (let i = 0; i < registros.length; i++) {
                lista_asignar.push({
                  value: registros[i].Nombre,
                  label: registros[i].Nombre,
                })
                setListaEmpleos(lista_asignar)
              }
            }
          }
        })
        .catch((e) => {
          alert(e.message)
        })
    }
  }, [step, setTouched])
  const estado_civil = [
    {label: 'Soltero', value: 'Soltero'},
    {label: 'Casado', value: 'Casado'},
    {label: 'Divorciado', value: 'Divorciado'},
  ]
  const options_country = useMemo(
    () =>
      countryList()
        .getData()
        .map((item) => {
          return {value: item.value, label: item.label}
        }),
    []
  )
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Información Personal</h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12 col-md-12'>
            <InputComplete
              errors={errors}
              touched={touched}
              label={'Nombre Completo'}
              name={'nombreCompleto'}
              placeholder='Nombre Completo'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputDatePicker
              errors={errors}
              touched={touched}
              label={'Fecha de Nacimiento'}
              name={'fechaNacimiento'}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Lugar de Nacimiento'}
              name={'lugarNacimiento'}
              placeholder='Lugar de Nacimiento'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Numero de Identificación'}
              name={'numeroIdentificacion'}
              placeholder='Numero de Identificación'
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Extendida en'}
              name={'extendidaIdentificacion'}
              placeholder='Extendida en'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              errors={errors}
              touched={touched}
              label={'Estado Civil'}
              name={'estadoCivil'}
              placeholder='Estado Civil'
              options={estado_civil}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              required={true}
              label={'Nacionalidad'}
              name={'nacionalidad'}
              placeholder='Nacionalidad'
              options={options_country}
            />
          </div>
          <div className='col-lg-12 col-md-12'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Profesión'}
              name={'profesion'}
              placeholder='Profesión'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              required={false}
              errors={errors}
              label={'No. afiliación Seguridad Social'}
              name={'noSeguridadSocial'}
              placeholder='No. afiliación Seguridad Social'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              required={false}
              label={'Núm. identificación tributaria'}
              name={'noIdentificacionTributaria'}
              placeholder='Núm. identificación tributaria'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'Tipo de Licencia'}
              name={'tipoLicencia'}
              placeholder='Tipo de Licencia'
              options={[
                {label: 'A', value: 'A'},
                {label: 'B', value: 'B'},
                {label: 'C', value: 'C'},
                {label: 'E', value: 'E'},
                {label: 'M', value: 'M'},
              ]}
              required={false}
              multiple={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            {values !== undefined &&
            'tipoLicencia' in values &&
            values['tipoLicencia'].length > 0 ? (
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Número'}
                name={'numeroLicencia'}
                placeholder='Número'
              />
            ) : null}
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'País de Residencia'}
              name={'paisResidencia'}
              placeholder='País de Residencia'
              options={options_country}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Dirección'}
              name={'direccion'}
              placeholder='Dirección'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Correo Electrónico'}
              name={'correoElectronico'}
              placeholder='Correo Electrónico'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Teléfono'}
              name={'telefono'}
              placeholder='Teléfono'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Religión'}
              name={'religion'}
              placeholder='Religión'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              errors={errors}
              touched={touched}
              label={'Empleo al que aspira'}
              name={'cargo'}
              placeholder='Cargo, posicion o tipo de empleo al que aspira'
              options={lista_empleos}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Indique el departamento en el que ud esta interesado'}
              name={'departamentoInteres'}
              placeholder='Indique el departamento en el que ud esta interesado'
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'Cómo conoció sobre nuestras oportunidades de empleo'}
              name={'comoNosConocio'}
              placeholder='Cómo conoció sobre nuestras oportunidades de empleo'
              required={false}
            />
          </div>

          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'¿Tiene algun familiar que trabaje en esta empresa?'}
              name={'familiar'}
              placeholder='TieneFamiliar'
              options={[
                {label: 'Si', value: 'Si'},
                {label: 'No', value: 'No'},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-3 col-md-3'>
            {values !== undefined && 'familiar' in values && values.familiar === 'Si' ? (
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Nombre del familiar'}
                name={'nombreFamiliar'}
                placeholder='Nombre del familiar'
              />
            ) : null}
          </div>
          <div className='col-lg-3 col-md-3'>
            {values !== undefined && 'familiar' in values && values.familiar === 'Si' ? (
              <Select2Complete
                touched={touched}
                errors={errors}
                label={'Parentesco del familiar'}
                name={'parentescoFamiliar'}
                placeholder=''
                options={[
                  {label: 'Padre', value: 'Padre'},
                  {label: 'Madre', value: 'Madre'},
                  {label: 'Hermano', value: 'Hermano'},
                  {label: 'Tio', value: 'Tio'},
                  {label: 'Esposo', value: 'Esposo'},
                  {label: 'Otro', value: 'Otro'},
                ]}
                required={true}
              />
            ) : null}
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'¿Tiene algún familiar que trabaje en el Gobierno?'}
              name={'familiarGobierno'}
              placeholder=''
              options={[
                {label: 'Si', value: 'Si'},
                {label: 'No', value: 'No'},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            {values !== undefined &&
            'familiarGobierno' in values &&
            values.familiarGobierno === 'Si' ? (
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Nombre del Familiar'}
                name={'nombreFamiliarGobierno'}
                placeholder='Nombre del familiar'
              />
            ) : null}
          </div>
          <div className='col-lg-6 col-md-6'>
            {values !== undefined &&
            'familiarGobierno' in values &&
            values.familiarGobierno === 'Si' ? (
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Dependencia del Familiar'}
                name={'dependenciaFamiliarGobierno'}
                placeholder='Dependencia'
              />
            ) : null}
          </div>
          <div className='col-lg-6 col-md-6'>
            {values !== undefined &&
            'familiarGobierno' in values &&
            values.familiarGobierno === 'Si' ? (
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Puesto del Familiar'}
                name={'puestoFamiliarGobierno'}
                placeholder='Puesto'
              />
            ) : null}
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'¿Tiene algún familiar que trabaje en el IGSS?'}
              name={'familiarIGSS'}
              placeholder=''
              options={[
                {label: 'Si', value: 'Si'},
                {label: 'No', value: 'No'},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            {values !== undefined && 'familiarIGSS' in values && values.familiarIGSS === 'Si' ? (
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Nombre del Familiar'}
                name={'nombreFamiliarIGSS'}
                placeholder='Nombre'
              />
            ) : null}
          </div>
          <div className='col-lg-6 col-md-6'>
            {values !== undefined && 'familiarIGSS' in values && values.familiarIGSS === 'Si' ? (
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Puesto del Familiar'}
                name={'puestoFamiliarIGSS'}
                placeholder='Puesto'
              />
            ) : null}
          </div>
          <div className='col-lg-6 col-md-6'>
            {values !== undefined && 'familiarIGSS' in values && values.familiarIGSS === 'Si' ? (
              <Select2Complete
                touched={touched}
                errors={errors}
                label={'Parentesco del familiar'}
                name={'parentescoFamiliarIGSS'}
                placeholder=''
                options={[
                  {label: 'Padre', value: 'Padre'},
                  {label: 'Madre', value: 'Madre'},
                  {label: 'Hermano', value: 'Hermano'},
                  {label: 'Tio', value: 'Tio'},
                  {label: 'Esposo', value: 'Esposo'},
                  {label: 'Otro', value: 'Otro'},
                ]}
                required={true}
              />
            ) : null}
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'¿Ha solicitado empleo anteriormente en esta empresa?'}
              name={'solicitadoEmpleoAnteriormente'}
              placeholder=''
              options={[
                {label: 'Si', value: 'Si'},
                {label: 'No', value: 'No'},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            {values !== undefined &&
            'solicitadoEmpleoAnteriormente' in values &&
            values.solicitadoEmpleoAnteriormente === 'Si' ? (
              <InputDatePicker
                errors={errors}
                touched={touched}
                label={'Fecha de solicitud'}
                name={'fechaSolicitudEmpleo'}
              />
            ) : null}
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2Complete
              touched={touched}
              errors={errors}
              label={'¿Tiene usted alguna limitación geográfica para trabajar?'}
              name={'limitacionGeografica'}
              placeholder=''
              options={[
                {label: 'Si', value: 'Si'},
                {label: 'No', value: 'No'},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            {values !== undefined &&
            'limitacionGeografica' in values &&
            values.limitacionGeografica === 'Si' ? (
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Especifique'}
                name={'detalleLimitacionGeografica'}
                placeholder='Especifique'
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export {Paso1}
