import * as Yup from 'yup'

export interface LaboralExperience{
  nombre: string
  telefono: string
  direccion: string
  giro: string
  fechaIngreso: Date
  fechaSalida?: Date
  cargoInicial: string
  cargoFinal: string
  funciones: string
  nombreJefe: string
  noPersonasACargo: number
  sueldoInicial: string
  sueldoFinal: string
  numeroSueldosAlAnio: number
  otrasPrestaciones: string
  motivoRetiro: string
  masGusto: string
  menosGusto: string
  pedirReferencias: string
}
export interface TituloUniversitario{
  universidad: string
  carrera: string
  concluido: boolean
  noCursosAprobados: number
  fechaGraduacion: Date
  premiosOMenciones: string
  estudiaActualmente: boolean
  horario: string
}
export interface Idioma{
 idioma: string
 nivelHablado: string
 nivelEscrito: string
}
export interface ReferenciaPersonal{
  nombre: string
  telefono: string
  relacion: string
}
export interface ReferenciaLaboral{
  nombre: string
  telefono: string
  empresa: string
  puesto: string
}
export interface JobRequest {
  //Paso 1
  nombreCompleto: string
  fechaNacimiento: Date
  lugarNacimiento: string
  numeroIdentificacion: string
  extendidaIdentificacion: string
  estadoCivil: string
  nacionalidad: string
  profesion: string
  noSeguridadSocial: string
  noIdentificacionTributaria: string
  tipoLicencia: string[]
  numeroLicencia: string
  paisResidencia: string
  direccion: string
  correoElectronico: string
  telefono: string
  religion: string
  cargo: string
  comoNosConocio: string
  departamentoInteres: string
  familiar: string
  nombreFamiliar: string
  parentescoFamiliar: string
  familiarGobierno: string
  nombreFamiliarGobierno: string
  dependenciaFamiliarGobierno: string
  puestoFamiliarGobierno: string
  familiarIGSS: string
  nombreFamiliarIGSS: string
  puestoFamiliarIGSS: string
  parentescoFamiliarIGSS: string
  solicitadoEmpleoAnteriormente: string
  fechaSolicitudEmpleo: Date
  limitacionGeografica: string
  detalleLimitacionGeografica: string
  //Paso 2
  nombrePadre: string
  telefonoPadre: string
  lugarTrabajoPadre: string
  nombreMadre: string
  telefonoMadre: string
  lugarTrabajoMadre: string
  noHermanos: number
  profesionHermanos: string
  noPersonasDependencia: number
  tieneConyuge: string
  nombreConyuge: string
  ocupacionConyuge: string
  nombreTrabajoConyuge: string
  direccionTrabajoConyuge: string
  telefonoTrabajoConyuge: string
  cargoTrabajoConyuge: string
  noHijos: number
  edadesHijos: string
  contactoEmergencia: string
  telefonoContactoEmergencia: string
  //Paso 3
  tipoVivienda: string
  vehiculoPropio: string
  modeloVehiculo: number
  otrosIngresos: string
  montoOtrosIngresos: number
  origenOtrosIngresos: string
  //Paso 4
  colegioDiversificado: string
  tituloObtenido: string
  fechaGraduacion?: Date
  conocimientoComputadoras?: boolean
  titulosUniversitarios: TituloUniversitario[]
  idiomas: Idioma[]
  //Paso 5
  experienciaLaboral: LaboralExperience[]
  //Paso 6
  organizaciones: string[]
  actividades: string[]
  //Paso 7
  enfermedad?: boolean
  detalleEnfermedad: string
  covid?: boolean
  vacunadoCovid?: boolean
  vacunaCovid: string
  dosisVacunaCovid: string
  embarazada?: boolean
  alergia: string
  tipoSangre: string
  fumaLicorDroga?: boolean
  detalleFumaLicorDroga: string
  tatuajes?: boolean
  //Paso 8
  dispuestoAViajar?: boolean
  pasaporteVigente?: boolean
  numPasaporte: string
  limitacionesHorario?: boolean
  detalleLimitacionesHorario: string
  discapacidadFisica: string
  expectativaSalaria: string
  fechaInicioTrabajo: Date
  //Paso 9
  referenciasPersonales: ReferenciaPersonal[]
  //Paso 10
  referenciasLaborales: ReferenciaLaboral[]
}

const createAccountSchemas = [
 
  Yup.object({
    nombreCompleto: Yup.string().required("El nombre completo es un campo requerido").label('Nombre Completo'),
    fechaNacimiento: Yup.date().required("Fecha de Nacimiento es un Campo requerido").label('Fecha de Nacimiento'),
    lugarNacimiento: Yup.string().required("Lugar de Nacimiento es un Campo requerido").label('Fecha de Nacimiento'),
    numeroIdentificacion: Yup.string().required("Numero de identificación es un Campo requerido").label('Número de Identificación'),
    extendidaIdentificacion: Yup.string().required("Extención de Identificación es requerido").label('Extendida en'), 
    estadoCivil: Yup.string().required("Estado civil es un campo requerido").label('Estado Civil'),
    nacionalidad: Yup.string().required("Nacionalidad es un campo requerido").label('Nacionalidad'),
    profesion: Yup.string().required("Profesion es un campo requerido").label('Profesion'),
    noSeguridadSocial: Yup.string().label('No. de Seguridad Social'),
    noIdentificacionTributaria: Yup.string().label('No. de Seguridad Social'),
    tipoLicencia: Yup.array().label('Tipo de Licencia'),
    numeroLicencia: Yup.string().when('tipoLicencia', {
      is: (tipoLicencia: any) => Array.isArray(tipoLicencia) && tipoLicencia.length > 0 , 
      then: (schema) => schema.required("Número de Licencia es un campo requerido"),
    }),
    paisResidencia: Yup.string().required("Pais de Residencia es un campo requerido").label('Pais de Residencia'),
    direccion: Yup.string().required("Dirección es un campo requerido").label('Dirección'),
    correoElectronico: Yup.string().email("Ingrese un correo valido").required("Correo Electrónico es un campo requerido").label('Correo Electrónico'),
    telefono: Yup.string().required("Teléfono es un campo requerido").label('Teléfono'),
    religion: Yup.string().required("Religión es un campo requerido").label('Religión'),
    cargo: Yup.string().required('Cargo o posición es un campo requerido').label("Cargo o posición"),
    comoNosConocio: Yup.string().label("Como Nos Conocio"),
    departamentoInteres: Yup.string().required("Departamento de Interes es un campo requerido").label("Departameto de Interes"),
    familiar: Yup.string().required("Tiene algun familiar que trabaje en esta empresa es un campo requerido"),
    nombreFamiliar: Yup.string().when('familiar', {
      is: (familiar: any) => familiar === 'Si',
      then: (schema) => schema.required("Nombre de Familiar es un campo requerido")
    }),
    parentescoFamiliar: Yup.string().when('familiar', {
      is: (familiar: any) => familiar === 'Si',
      then: (schema) => schema.required("Parentesco de Familiar es un campo requerido")
    }),
    familiarGobierno: Yup.string().required("Tiene algun familiar que trabaje en el gobierno es un campo requerido"),
    nombreFamiliarGobierno: Yup.string().when('familiarGobierno', {
      is: (familiarGobierno: any) => familiarGobierno === 'Si',
      then: (schema) => schema.required("Nombre de Familiar que trabaja en el gobierno es un campo requerido")
    }),
    dependenciaFamiliarGobierno:  Yup.string().when('familiarGobierno', {
      is: (familiarGobierno: any) => familiarGobierno === 'Si',
      then: (schema) => schema.required("Dependecia de Familiar que trabaja en el gobierno es un campo requerido")
    }),
    puestoFamiliarGobierno:  Yup.string().when('familiarGobierno', {
      is: (familiarGobierno: any) => familiarGobierno === 'Si',
      then: (schema) => schema.required("Puesto de Familiar que trabaja en el gobierno es un campo requerido")
    }),
    familiarIGSS: Yup.string().required("Tiene algun familiar que trabaje en el IGSS es un campo requerido"),
    nombreFamiliarIGSS: Yup.string().when('familiarIGSS', {
      is: (familiarIGSS: any) => familiarIGSS === 'Si',
      then: (schema) => schema.required("Nombre del familiar que trabaja en el IGSS es un campo requerido")
    }),
    puestoFamiliarIGSS: Yup.string().when('familiarIGSS', {
      is: (familiarIGSS: any) => familiarIGSS === 'Si',
      then: (schema) => schema.required("Puesto del familiar que trabaja en el IGSS es un campo requerido")
    }),
    parentescoFamiliarIGSS: Yup.string().when('familiarIGSS', {
      is: (familiarIGSS: any) => familiarIGSS === 'Si',
      then: (schema) => schema.required("Parentesco del familiar que trabaja en el IGSS es un campo requerido")
    }),
    solicitadoEmpleoAnteriormente: Yup.string().required("Ha solicitado anteriormente empleo es un campo requerido"),
    fechaSolicitudEmpleo: Yup.string().when('solicitadoEmpleoAnteriormente', {
      is: (solicitadoEmpleoAnteriormente: any) => solicitadoEmpleoAnteriormente === 'Si',
      then: (schema) => schema.required("Fecha anterior de solicitud de empleo es un campo requerido")
    }),
    limitacionGeografica: Yup.string().required("Limitación geográfica es un campo requerido"),
    detalleLimitacionGeografica:  Yup.string().when('limitacionGeografica', {
      is: (limitacionGeografica: any) => limitacionGeografica === 'Si',
      then: (schema) => schema.required("Detalle de limitación geográfica es un campo requerido")
    }),
  }),
  //Paso 2
  Yup.object({
    nombrePadre: Yup.string(),
    telefonoPadre: Yup.string(),
    lugarTrabajoPadre: Yup.string(),
    nombreMadre: Yup.string(),
    telefonoMadre: Yup.string(),
    lugarTrabajoMadre: Yup.string(),
    noHermanos: Yup.number().typeError('Campo debe de ser numérico').required("Número de Hermanos es un campo requerido"),
    profesionHermanos: Yup.string().when("noHermanos", {
      is: (noHermanos: any) => noHermanos > 0,
      then: (schema) => schema.required("Profesión de hermanos es un campo requerido")
    }),
    noPersonasDependencia: Yup.number().typeError('Campo debe de ser numérico').required("No de Personas que dependen de ud es un campo requerido"),
    tieneConyuge: Yup.string().required("Tiene conyuge es un campo requerido"),
    nombreConyuge: Yup.string().when("tieneConyuge", {
      is: (tieneConyuge: any) => tieneConyuge === "Si",
      then: (schema) => schema.required("Nombre de conyuge es un campo requerido")
    }),
    ocupacionConyuge: Yup.string().when("tieneConyuge", {
      is: (tieneConyuge: any) => tieneConyuge === "Si",
      then: (schema) => schema.required("Ocupación de conyuge es un campo requerido")
    }),
    nombreTrabajoConyuge:Yup.string().when("tieneConyuge", {
      is: (tieneConyuge: any) => tieneConyuge === "Si",
      then: (schema) => schema.required("Trabajo de conyuge es un campo requerido")
    }),
    direccionTrabajoConyuge: Yup.string().when("tieneConyuge", {
      is: (tieneConyuge: any) => tieneConyuge === "Si",
      then: (schema) => schema.required("Dirección de trabajo de conyuge es un campo requerido")
    }),
    telefonoTrabajoConyuge: Yup.string().when("tieneConyuge", {
      is: (tieneConyuge: any) => tieneConyuge === "Si",
      then: (schema) => schema.required("Teléfono de trabajo de conyuge es un campo requerido")
    }),
    cargoTrabajoConyuge: Yup.string().when("tieneConyuge", {
      is: (tieneConyuge: any) => tieneConyuge === "Si",
      then: (schema) => schema.required("Cargo de trabajo de conyuge es un campo requerido")
    }),
    noHijos: Yup.number().typeError('Campo debe de ser numérico').required("Número de hijos es un campo requerido"),
    edadesHijos: Yup.string().when("noHijos", {
      is: (noHijos: any) => noHijos > 0,
      then: (schema) => schema.required("Edades de hijos es un campo requerido")
    }),
    contactoEmergencia: Yup.string().required("Contacto de emergencia es un campo requerido"),
    telefonoContactoEmergencia: Yup.string().required("Teléfono de contacto de emergencia es un campo requerido"),
    
  }),
  //Paso 3
  Yup.object(
    {
      tipoVivienda: Yup.string().required("Tipo de vivienda es un campo requerido"),
      vehiculoPropio: Yup.string().required("Posee vehiculo propio es un campo requerido"),
      modeloVehiculo: Yup.number().typeError('Campo debe de ser numérico').when("vehiculoPropio", {
        is: (vehiculoPropio: any) => vehiculoPropio === "Si",
        then: (schema) => schema.required("El modelo de vehiculo es un campo requerido")
      }),
      otrosIngresos: Yup.string().required("Otros ingresos es un campo requerido"),
      montoOtrosIngresos: Yup.number().typeError('Campo debe de ser numérico').when("otrosIngresos", {
        is: (otrosIngresos: any) => otrosIngresos === "Si",
        then: (schema) => schema.required("Monto de otros ingresos es un campo requerido")
      }),
      origenOtrosIngresos: Yup.string().when("otrosIngresos", {
        is: (otrosIngresos: any) => otrosIngresos === "Si",
        then: (schema) => schema.required("Origen de otros ingresos es un campo requerido")
      }),
    }
  ),
  
  //Paso 4
  Yup.object({
    colegioDiversificado: Yup.string(),
    tituloObtenido: Yup.string(),
    fechaGraduacion: Yup.date(),
    conocimientoComputadoras: Yup.boolean().required("Campo requerido"),
    titulosUniversitarios: Yup.array().of(
      Yup.object().shape({
        universidad: Yup.string().required("Campo requerido"),
        carrera: Yup.string().required("Campo requerido"),
        concluido: Yup.boolean().required("Campo requerido"),
        noCursosAprobados: Yup.number().typeError('Campo debe de ser numérico').required("Campo requerido"),
        fechaGraduacion: Yup.date().when('concluido', {
          is: (concluido: any) => concluido === true,
          then: (schema) => schema.required("Campo requerido")
        }),
        premiosOMenciones: Yup.string(),
        estudiaActualmente: Yup.boolean().required("Campo requerido"),
        horario: Yup.string().when('estudiaActualmente', {
          is: (estudiaActualmente: any) => estudiaActualmente === true,
          then: (schema) => schema.required("Campo requerido")
        }),
      })
    ),
    idiomas: Yup.array().of(
      Yup.object().shape({
        idioma: Yup.string().required("Campo requerido"),
        nivelHablado: Yup.string().required("Campo requerido"),
        nivelEscrito: Yup.string().required("Campo requerido")
      })
    ),
  }),
   //Paso 5
   Yup.object({
    experienciaLaboral: Yup.array().of(
      Yup.object().shape({
        nombre: Yup.string().required("Nombre de la empresa es un campo requerido"),
        telefono: Yup.string().required("Teléfono de la empresa es un campo requerido"),
        direccion: Yup.string().required("Dirección de la empresa es un campo requerido"),
        giro: Yup.string().required("Giro de la empresa es un campo requerido"),
        fechaIngreso: Yup.date().required("Fecha de ingreso a la empresa es un campo requerido"),
        fechaSalida: Yup.date(),
        cargoInicial: Yup.string().required('Cargo inicial es un campo requerido'),
        cargoFinal: Yup.string(),
        nombreJefe: Yup.string().required("Nombre de jefe es un campo requerido"),
        funciones: Yup.string().required("Funciones desempeñadas es un campo requerido"),
        noPersonasACargo: Yup.number().typeError('Campo debe de ser numérico'),
        sueldoInicial: Yup.number().typeError('Campo debe de ser numérico').required("Sueldo inicial es un campo requerido"),
        sueldoFinal: Yup.number().typeError('Campo debe de ser numérico'),
        numeroSueldosAlAnio: Yup.number().typeError('Campo debe de ser numérico'),
        otrasPrestaciones: Yup.string(),
        motivoRetiro: Yup.string(),
        masGusto: Yup.string().required('Campo requerido'),
        menosGusto: Yup.string().required('Campo requerido'),
        pedirReferencias: Yup.string().required('Campo requerido'),
        
      })
    )
  }),
   //Paso 6
   Yup.object({
    organizaciones: Yup.array().of(Yup.string().required("Campo requerido")),
    actividades: Yup.array().of(Yup.string().required("Campo requerido")),
  }),
  //Paso 7
  Yup.object({
    enfermedad: Yup.boolean().required("Campo requerido"),
    detalleEnfermedad:  Yup.string().when('enfermedad', {
      is: (value: any) => value === true,
      then: (schema) => schema.required("Campo requerido")
    }),
    covid: Yup.boolean().required("Campo requerido"),
    vacunadoCovid: Yup.boolean().required("Campo requerido"),
    vacunaCovid: Yup.string().when('vacunadoCovid', {
      is: (value: any) => value === true,
      then: (schema) => schema.required("Campo requerido")
    }),
    dosisVacunaCovid:  Yup.string().when('vacunaCovid', {
      is: (value: any) => value === true,
      then: (schema) => schema.required("Campo requerido")
    }),
    embarazada: Yup.boolean().required("Campo requerido"),
    alergia: Yup.string(),
    tipoSangre: Yup.string().required("Campo requerido"),
    fumaLicorDroga: Yup.boolean().required("Campo requerido"),
    detalleFumaLicorDroga:  Yup.string().when('fumaLicorDroga', {
      is: (value: any) => value === true,
      then: (schema) => schema.required("Campo requerido")
    }),
    tatuajes: Yup.boolean().required("Campo requerido"),
    }),
  //Paso 8
  Yup.object({
    dispuestoAViajar: Yup.boolean().required("Campo requerido"),
    pasaporteVigente: Yup.boolean().required("Campo requerido"),
    numPasaporte: Yup.string(),
    limitacionesHorario: Yup.boolean().required("Campo requerido"),
    detalleLimitacionesHorario: Yup.string(),
    discapacidadFisica: Yup.string(),
    expectativaSalaria: Yup.string().required("Campo requerido"),
    fechaInicioTrabajo: Yup.date().required("Campo requerido"),
  }),
  //Paso 9
  Yup.object({
    referenciasPersonales: Yup.array().of(
      Yup.object().shape({
        nombre: Yup.string().required("Campo requerido"),
        telefono: Yup.string().required("Campo requerido"),
        relacion: Yup.string().required("Campo requerido"),
      })
    ),
  }),
  //Paso 10
  Yup.object({
    referenciasLaborales: Yup.array().of(
      Yup.object().shape({
        nombre: Yup.string().required("Campo requerido"),
        telefono: Yup.string().required("Campo requerido"),
        empresa: Yup.string().required("Campo requerido"),
        puesto: Yup.string().required("Campo requerido"),
      })
    ),
  }),
]

const inits: JobRequest = {
  nombreCompleto: '',
  fechaNacimiento: new Date('01/01/2000'),
  lugarNacimiento: '',
  numeroIdentificacion: '',
  extendidaIdentificacion: '',
  estadoCivil: '',
  nacionalidad: '',
  profesion: '',
  noSeguridadSocial: '',
  noIdentificacionTributaria: '',
  tipoLicencia: [],
  numeroLicencia: '',
  paisResidencia: '',
  direccion: '',
  correoElectronico: '@',
  telefono: '',
  religion: '',
  cargo: '',
  comoNosConocio: '',
  departamentoInteres: '',
  familiar: '',
  nombreFamiliar: '',
  parentescoFamiliar: '',
  familiarGobierno: '',
  nombreFamiliarGobierno: '',
  dependenciaFamiliarGobierno: '',
  puestoFamiliarGobierno: '',
  familiarIGSS: '',
  nombreFamiliarIGSS: '',
  puestoFamiliarIGSS: '',
  parentescoFamiliarIGSS: '',
  solicitadoEmpleoAnteriormente: '',
  fechaSolicitudEmpleo: new Date(),
  limitacionGeografica: '',
  detalleLimitacionGeografica: '',
  //PASO 2
  nombrePadre: '',
  telefonoPadre: '',
  lugarTrabajoPadre: '',
  nombreMadre: '',
  telefonoMadre: '',
  lugarTrabajoMadre: '',
  noHermanos: 0,
  profesionHermanos: '',
  noPersonasDependencia: 0,
  tieneConyuge: '',
  nombreConyuge: '',
  ocupacionConyuge: '',
  nombreTrabajoConyuge: '',
  direccionTrabajoConyuge: '',
  telefonoTrabajoConyuge: '',
  cargoTrabajoConyuge: '',
  noHijos: 0,
  edadesHijos: '',
  contactoEmergencia: '',
  telefonoContactoEmergencia: '',
  //Paso 3
  tipoVivienda: '',
  vehiculoPropio: '',
  modeloVehiculo: 2000,
  otrosIngresos: '',
  montoOtrosIngresos: 0,
  origenOtrosIngresos: '',
  //Paso 4
  colegioDiversificado: '',
  tituloObtenido: '',
  fechaGraduacion: undefined,
  conocimientoComputadoras: undefined,
  titulosUniversitarios: [],
  idiomas: [],
  //Paso 5
  experienciaLaboral: [],
  //Paso 6
  organizaciones: [],
  actividades: [],
  //Paso 7
  enfermedad: undefined,
  detalleEnfermedad: '',
  covid: undefined,
  vacunadoCovid: undefined,
  vacunaCovid: '',
  dosisVacunaCovid: '',
  embarazada: undefined,
  alergia: '',
  tipoSangre: '',
  fumaLicorDroga: undefined,
  detalleFumaLicorDroga: '',
  tatuajes: undefined,
  //Paso 8
  dispuestoAViajar: undefined,
  pasaporteVigente: undefined,
  numPasaporte: '',
  limitacionesHorario: undefined,
  detalleLimitacionesHorario: '',
  discapacidadFisica: '',
  expectativaSalaria: '',
  fechaInicioTrabajo: new Date(),
  //Paso 9
  referenciasPersonales: [
    {
    nombre: '',
    telefono: '',
    relacion: '',
    },
    {
      nombre: '',
      telefono: '',
      relacion: '',
    },    
    {
      nombre: '',
      telefono: '',
      relacion: '',
    },    
],
  //Paso 10
  referenciasLaborales: [ {
    nombre: '',
    telefono: '',
    puesto: '',
    empresa: '',
    },
    {
      nombre: '',
      telefono: '',
      puesto: '',
      empresa: '',
    },    
    {
      nombre: '',
      telefono: '',
      puesto: '',
      empresa: '',
    },    ],
}
export function calcularEdadEnAnios(fecha: Date) {
  const fechaNacimiento = fecha;
  const fechaActual = new Date();
  
  // Restamos el año de nacimiento al año actual
  const edadEnMilisegundos = fechaActual.getTime() - fechaNacimiento.getTime();

  
  // Convertimos la diferencia en milisegundos a años
  const milisegundosEnUnAnio = 1000 * 60 * 60 * 24 * 365.25; // Aproximadamente 365.25 días en un año
  const edadEnAnios = edadEnMilisegundos / milisegundosEnUnAnio;

  return Math.floor(edadEnAnios);
}
export function formatearFecha(fecha: Date): string {
  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1; // Los meses comienzan desde 0, por lo que sumamos 1
  const anio = fecha.getFullYear();

  // Asegurarse de que el día y el mes tengan dos dígitos
  const diaFormateado = dia < 10 ? `0${dia}` : dia.toString();
  const mesFormateado = mes < 10 ? `0${mes}` : mes.toString();

  // Formatear la fecha como "d/m/Y"
  return `${diaFormateado}/${mesFormateado}/${anio}`;
}
export function formatoSiNo(obj?: boolean): string {
  if(obj !== undefined){
    return obj ? "Si" : "No"
  }else{
    return ""
  }

}
export {createAccountSchemas, inits}
