import React, {useEffect} from 'react'
import InputComplete from './InputComplete'
import Select2Complete from './Select2Complete'
import Select2CompleteBoolean from './Select2CompleteBoolean'
type PasosProps = {
  errors: {}
  touched: {}
  values: any
  setTouched: any
  step: number
}
const Paso7 = ({errors, touched, values, setTouched, step}: PasosProps) => {
  useEffect(() => {
    setTouched({})
  }, [step, setTouched])
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Salud en General</h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6 col-md-6'>
            <Select2CompleteBoolean
              touched={touched}
              errors={errors}
              label={'¿Ha tenido alguna enfermedad en los últimos 3 años?'}
              name={`enfermedad`}
              placeholder={'¿Ha tenido alguna enfermedad en los últimos 3 años?'}
              options={[
                {label: 'Si', value: true},
                {label: 'No', value: false},
              ]}
              required={true}
            />
          </div>
          {values.enfermedad ? (
            <div className='col-lg-6 col-md-6'>
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Especifique'}
                name={`detalleEnfermedad`}
                placeholder='Especifique'
                required={true}
              />
            </div>
          ) : null}
          <div className='col-lg-6 col-md-6'>
            <Select2CompleteBoolean
              touched={touched}
              errors={errors}
              label={'¿Ha padecido Covid-19?'}
              name={`covid`}
              placeholder={'¿Ha padecido Covid-19?'}
              options={[
                {label: 'Si', value: true},
                {label: 'No', value: false},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2CompleteBoolean
              touched={touched}
              errors={errors}
              label={'¿Está vacunado contra Covid-19?'}
              name={`vacunadoCovid`}
              placeholder={'¿Está vacunado contra Covid-19?'}
              options={[
                {label: 'Si', value: true},
                {label: 'No', value: false},
              ]}
              required={true}
            />
          </div>
          {values.vacunadoCovid ? (
            <>
              <div className='col-lg-6 col-md-6'>
                <InputComplete
                  touched={touched}
                  errors={errors}
                  label={'Nombre de la Vacuna'}
                  name={`vacunaCovid`}
                  placeholder={'Nombre de la Vacuna'}
                  required={false}
                />
              </div>
              <div className='col-lg-6 col-md-6'>
                <Select2Complete
                  touched={touched}
                  errors={errors}
                  label={'¿Que dosis posee?'}
                  name={`dosisVacunaCovid`}
                  placeholder={'¿Que dosis posee?'}
                  options={[
                    {label: 'Primera', value: 'Primera'},
                    {label: 'Segunda', value: 'Segunda'},
                    {label: 'Tercera', value: 'Tercera'},
                    {label: 'Cuarta', value: 'Cuarta'},
                  ]}
                  required={true}
                />
              </div>
            </>
          ) : (
            false
          )}
          <div className='col-lg-6 col-md-6'>
            <Select2CompleteBoolean
              touched={touched}
              errors={errors}
              label={'¿Esta embarazada?'}
              name={`embarazada`}
              placeholder={'¿Esta embarazada?'}
              options={[
                {label: 'Si', value: true},
                {label: 'No', value: false},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'¿A que medicamentos es alergico?'}
              name={`alergia`}
              placeholder={'¿A que medicamentos es alergico?'}
              required={false}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <InputComplete
              touched={touched}
              errors={errors}
              label={'¿Qué tipo de sangre tiene?'}
              name={`tipoSangre`}
              placeholder={'¿Qué tipo de sangre tiene?'}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2CompleteBoolean
              touched={touched}
              errors={errors}
              label={'¿Tiene tatuajes?'}
              name={`tatuajes`}
              placeholder={'¿Tiene tatuajes?'}
              options={[
                {label: 'Si', value: true},
                {label: 'No', value: false},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            <Select2CompleteBoolean
              touched={touched}
              errors={errors}
              label={'¿Fuma, ingiere licor o algún tipo de droga?'}
              name={`fumaLicorDroga`}
              placeholder={'¿Fuma, ingiere licor o algún tipo de droga?'}
              options={[
                {label: 'Si', value: true},
                {label: 'No', value: false},
              ]}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6'>
            {values.fumaLicorDroga ? (
              <InputComplete
                touched={touched}
                errors={errors}
                label={'Especifique'}
                name={`detalleFumaLicorDroga`}
                placeholder={'Especifique'}
                required={true}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Paso7
